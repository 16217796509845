import supabase from "./supabase";
import { reportError } from "./helpers";

const DB = {
  supabase,

  select: async (table, columns = "*") => {
    const { data, error } = await supabase.from(table).select(columns);

    if (error) {
      reportError(error, { table, columns });
      return null;
    }
    return data;
  },

  upsert: async (table, records, returnColumns) => {
    const sendRecords = records.map((record) =>
      Object.keys(record).reduce(
        (acc, key) => {
          if (key === "id") {
            if (!(record[key] === null || record[key] === undefined)) {
              acc[key] = record[key];
            }
          } else {
            acc[key] = record[key];
          }
          return acc;
        },
        { updated_at: new Date().toISOString() },
      ),
    );

    const { data, error } = await supabase
      .from(table)
      .upsert(sendRecords, { defaultToNull: false })
      .select(returnColumns);

    if (error) {
      reportError(error, { table, sendRecords });
      return false;
    }
    return data;
  },

  deleteExceptIds: async (table, validIds) => {
    const formattedUUIDs = validIds.join(",");

    const { data, error } = await supabase
      .from(table)
      .delete()
      .not("id::varchar", "in", `(${formattedUUIDs})`);

    if (error) {
      reportError(error, { table, formattedUUIDs });
      return false;
    }
    return data;
  },
};

export default DB;
