import React from "react";
import BaseSegment, { TextContent } from "./base";
import SegmentMenu from "./menu";

function AddSegment(props) {
  const { day } = props;

  const styles = {
    activeBannerStyle: "bg-slate-600 text-white",
    idleBannerStyle: "bg-slate-100 text-slate-600",
    activeBorderStyle: "border-slate-700",
    idleBorderStyle: "border-slate-300",
    icon: "fa-plus",
  };

  const renderMenu = (doClose) => <SegmentMenu day={day} doClose={doClose} />;

  return (
    <BaseSegment style={styles} renderMenu={renderMenu}>
      <TextContent className="text-slate-500">Add plans</TextContent>
    </BaseSegment>
  );
}

export default AddSegment;
