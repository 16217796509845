import React, { useEffect, useState } from "react";
import Auth from "../../stores/auth.js";
import Wishlist from "../../stores/wishlist.js";
import Modal from "../modal.jsx";
import SignupPitch from "../upsell/signup_pitch.jsx";
import { reportEvent } from "../../lib/helpers.js";

function WishlistSidebar(props) {
  const { locationId, title } = props;
  const { isSyncing } = Wishlist.useStore();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onList = () => Wishlist.isOnList(locationId);

  const handleClick = () => {
    if (onList()) {
      window.location.href = "/my/wishlist/";
      return;
    }

    Wishlist.setOnList(locationId, true);

    if (!Auth.isAuth()) {
      reportEvent("show_pitch", { view: "hero", id: locationId });
      setIsPopupVisible(true);
    }
  };

  const renderWishlistAction = () => (
    <div className="bg-indigo-800 p-1 p-2 align-middle text-sm">
      <span className="ml-1 inline-block font-bold">
        <i className="fas fa-check" /> Wishlisted &rarr;
      </span>
    </div>
  );

  const renderDescription = () => (
    <>
      <div className="bg-indigo-800 p-1 p-2 align-middle text-sm">
        <span className="ml-1 inline-block font-bold">
          <i className="fas fa-plus" /> Add to My Journey
        </span>
      </div>
      <div className="p-2 text-center text-sm font-light">
        <ul className="">
          <li>
            <b className="font-medium">
              <i className="fas fa-search" /> Custom Finds
            </b>
            : Tailored for you.
          </li>
          <li>
            <b className="font-medium">
              <i className="fas fa-bell" /> Stay Informed
            </b>
            : Real-time alerts.
          </li>
          <li>
            <b className="font-medium">
              <i className="fas fa-map-marked-alt" /> Easy Plan
            </b>
            : Visualize your trip.
          </li>
          <li>
            <b className="font-medium">
              <i className="fas fa-heart" /> Match Made
            </b>
            : Explore your interests.
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <button
        onClick={handleClick}
        className="
          w-full
          rounded-lg
          border-2
          border-indigo-800
          bg-indigo-600
          text-center
          text-white
          shadow-md
          hover:border-indigo-900
          hover:bg-indigo-700
          focus:border-indigo-900
          focus:bg-indigo-700
        "
      >
        {onList() ? renderWishlistAction() : renderDescription()}
      </button>
      {isPopupVisible && (
        <Modal>
          <SignupPitch title={title} />
        </Modal>
      )}
    </>
  );
}

export default WishlistSidebar;
