import React, { useEffect } from "react";
import Wishlist from "../stores/wishlist";
import Trip from "../stores/trip";
import { isAuth } from "../stores/auth";
import MapStore from "../stores/map";

function LocationPopup(props) {
  const { location } = props;

  // Force a re-render when the Trip state changes
  const { days } = Trip.useStore();
  const { localItems } = Wishlist.useStore();

  // The popup needs the Trip state, even if we're only showing the Wishlist tab.
  const loadInitialTripState = Trip.init;
  useEffect(() => {
    loadInitialTripState();
  }, [loadInitialTripState]);

  const renderWishlistButton = () => {
    const searchResult = Trip.findLocationSegment(location);

    if (searchResult === null && !Wishlist.isOnList(location.id)) {
      return (
        <button
          className="flex-grow cursor-pointer bg-yellow-50 p-2 text-yellow-800"
          onClick={() => {
            Wishlist.setOnList(location.id, true);
            MapStore.refresh();
          }}
        >
          <i className="fas fa-plus mr-1" />
          Add to Wishlist
        </button>
      );
    }
  };

  // TODO: Tapping an item on the Plan tab should show the popup. Then we can get rid of some of the buttons from there???

  const renderPlanButton = () => {
    const searchResult = Trip.findLocationSegment(location);

    if (searchResult === null) {
      return (
        <button
          className="flex-grow cursor-pointer bg-teal-50 p-2 text-teal-600"
          onClick={async () => {
            if (isAuth()) {
              Trip.addLocationToEnd(location);
              MapStore.refresh();
              // Open the Plan tab
              // TODO: ideally scroll to the new item
              MapStore.setTab("plan", false);
            } else {
              // Show the Plan tab inviting them to log in
              MapStore.setTab("plan");
              // TODO: In the meantime, just show a message. It's shit, but it'll work.
            }
          }}
        >
          <i className="fas fa-plus mr-1" />
          Add to Plan
        </button>
      );
    }
  };

  const renderCloseBar = () =>
    location.closed && (
      <div className="bg-red-50 p-2 text-red-600">
        <i className="fas fa-exclamation-triangle" />
        <span className="pl-1 text-sm">{location.closed}</span>
      </div>
    );

  const renderPlanBar = () => {
    const searchResult = Trip.findLocationSegment(location);

    // TODO: Add a Remove button

    return (
      searchResult && (
        <div className="bg-teal-50 p-2 text-teal-600">
          <i className="fas fa-check-circle" />
          <span className="pl-1 text-sm">
            Planned for Day {searchResult.day.seq + 1}
          </span>
        </div>
      )
    );
  };

  const renderWishlistBar = () => {
    const searchResult = Trip.findLocationSegment(location);

    // TODO: Add a Remove button ???

    if (searchResult === null && Wishlist.isOnList(location.id)) {
      return (
        <div className="bg-yellow-50 p-2 text-yellow-800">
          <i className="fas fa-map-marked-alt" />
          <span className="pl-1 text-sm">On your Wishlist</span>
        </div>
      );
    }
  };

  const renderBars = () => (
    <>
      {renderCloseBar()}
      {renderPlanBar()}
      {renderWishlistBar()}
    </>
  );

  return (
    <div className="w-80">
      <div className="relative">
        <img
          src={location.thumbnail_url}
          alt={location.title}
          className="aspect-video bg-cover"
        />
        <div className="absolute bottom-0 right-0">
          <a
            href={location.permalink}
            target="_blank"
            className="inline-block bg-white p-2 hover:bg-teal-50 hover:underline focus:bg-teal-50 focus:underline"
            rel="noreferrer"
          >
            Learn more &rarr;
          </a>
        </div>
      </div>
      {renderBars()}
      <div className="px-2">
        <h2 className="text-lg font-bold">{location.title}</h2>
        <div className="mb-2 text-sm text-slate-700">
          in {location.neighbourhood}, {location.region}
        </div>
      </div>
      <div className="flex text-sm">
        {renderPlanButton()}
        {renderWishlistButton()}
      </div>
    </div>
  );
}

export default LocationPopup;
