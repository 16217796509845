import React, { useState } from "react";
import Wishlist from "../../../stores/wishlist";
import Trip from "../../../stores/trip";
import Recommender from "../../../lib/recommender";
import MenuSection from "./menu/section";
import SearchSection from "./menu/search";
import NoteSection from "./menu/note";
import URLSection from "./menu/url";
import RelocateSection from "./menu/relocate";
import AccommodationSection from "./menu/accommodation";
import LocationButton from "./menu/location";

function SegmentMenu(props) {
  const { day, doClose } = props;

  const { isLocalLoaded: isWishlistLoaded } = Wishlist.useStore();

  const [isWishlistOpen, setWishlistOpen] = useState(false);
  const [isSuggestionsOpen, setSuggestionsOpen] = useState(false);

  const addLocationToDay = (id) => {
    Trip.addToDay(day, { type: "Location", locationId: id });
    doClose ? doClose() : null;
  };

  // TODO: The form should be able to be cancelled.

  const renderLocation = (location) => {
    if (location) {
      return <LocationButton location={location} onAdd={addLocationToDay} />;
    }
  };

  const renderItem = (contents, classNames, doClick) => (
    <MenuSection
      classNames={classNames}
      onClick={doClick}
      doCloseParent={doClose}
    >
      {contents}
    </MenuSection>
  );

  const lastRegion = Recommender.lastRegionForDay(day);
  const regionWishlist = Recommender.wishlistForDay(day);

  const renderRegionWishlist = () => {
    if (isWishlistLoaded && isWishlistOpen) {
      if (regionWishlist.length === 0) {
        return <p>All items on the wishlist are on the itinerary.</p>;
      }
      return regionWishlist.map(renderLocation);
    }
  };

  return (
    <ul className="grid grid-cols-1 text-sm">
      <SearchSection day={day} doCloseParent={doClose} />
      <AccommodationSection day={day} doCloseParent={doClose} />
      <RelocateSection day={day} doCloseParent={doClose} />
      <NoteSection day={day} doCloseParent={doClose} />
      <URLSection day={day} doCloseParent={doClose} />
      <MenuSection
        icon="fa-calendar-plus"
        text="Add Day"
        doClick={() => Trip.addDay(day.seq)}
        doCloseParent={doClose}
      />
      <li
        className="col-span-1 cursor-pointer bg-teal-600 px-2 py-3 text-sm text-white hover:bg-teal-700"
        onClick={(ev) => {
          ev.stopPropagation();
          setWishlistOpen(!isWishlistOpen);
        }}
      >
        Nearby Wishlist &raquo;
      </li>
      {renderRegionWishlist()}
      {TRUNDLE_PROFILE === "staging" && (
        <>
          <li
            className="col-span-1 cursor-pointer bg-indigo-600 px-2 py-3 text-sm text-white hover:bg-indigo-700"
            onClick={(ev) => {
              ev.stopPropagation();
              setSuggestionsOpen(!isSuggestionsOpen);
            }}
          >
            Suggestions &raquo;
          </li>
          {isSuggestionsOpen
            ? Recommender.suggestions().map(renderLocation)
            : null}
        </>
      )}
    </ul>
  );
}

export default SegmentMenu;
