import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import MapboxConfig from "../config/mapbox";
import MainMap from "./main";
import {
  locationsToGeoJSON,
  createArcPath,
  filterLocationDuplicates,
} from "../lib/geojson";
import MapStore from "../stores/map";
import { NODES_URL } from "../stores/directions";
import AtlasStore from "../stores/atlas";
import LocationPopup from "./location_popup";

const defaultSource = {
  cluster: true,
  clusterMaxZoom: 13,
  clusterRadius: 25,
};

const layerDefaultDefs = (layerName, style) => {
  const defaultStyles = MapboxConfig.styles.default;
  const styles = style || defaultStyles;

  const defs = {
    clusters: {
      type: "circle",
      filter: ["has", "point_count"],
      layout: {},
      paint: {
        "circle-color": styles.clusterBackground,
        "circle-radius": [
          "step",
          ["get", "point_count"],
          Math.floor(
            (styles.markerRadius || defaultStyles.markerRadius) * 1.25,
          ),
          5,
          Math.floor((styles.markerRadius || defaultStyles.markerRadius) * 1.5),
          15,
          Math.floor((styles.markerRadius || defaultStyles.markerRadius) * 2.0),
        ],
        "circle-stroke-width": styles.borderWidth,
        "circle-stroke-color": styles.markerBorder,
      },
    },
    "cluster-count": {
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": styles.clusterTextSize || defaultStyles.clusterTextSize,
      },
      paint: {
        "text-color": styles.clusterText,
        "text-halo-color": styles.clusterBackground,
        "text-halo-width": 1.5,
      },
    },
    unclustered: {
      type: "circle",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": styles.markerBackground,
        "circle-radius": styles.markerRadius || defaultStyles.markerRadius,
        "circle-stroke-width": styles.borderWidth,
        "circle-stroke-color": styles.markerBorder,
      },
      minzoom: styles.markerMinzoom || 1,
    },
    "unclustered-title": {
      type: "symbol",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "text-field": "{title}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": styles.markerTextSize || defaultStyles.markerTextSize,
        "text-offset": [0, 0],
      },
      paint: {
        "text-color": styles.markerText,
        "text-halo-color": styles.markerHaloColor,
        "text-halo-width": 1.5,
      },
      minzoom: styles.textMinzoom || 12,
    },
  };
  return defs[layerName];
};

function PlannerMap(props) {
  const mapContainerRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [popupInstances, setPopupInstances] = useState([]);
  const {
    plan,
    wishlist,
    lat,
    lng,
    zoom,
    state: layerState,
    isZoomed,
    popupTarget,
    isSidebarOpen,
  } = MapStore.useStore();

  const { locations: atlas } = AtlasStore.useStore();

  const addClusteredLayers = (map, sourceName, style, fn) => {
    map.addLayer({
      ...layerDefaultDefs("clusters", style),
      id: `${sourceName}-clusters`,
      source: sourceName,
    });
    map.addLayer({
      ...layerDefaultDefs("cluster-count", style),
      id: `${sourceName}-cluster-count`,
      source: sourceName,
    });
    map.addLayer({
      ...layerDefaultDefs("unclustered", style),
      id: `${sourceName}-unclustered`,
      source: sourceName,
    });
    map.addLayer({
      ...layerDefaultDefs("unclustered-title", style),
      id: `${sourceName}-unclustered-title`,
      source: sourceName,
    });

    // inspect a cluster on click
    map.on("click", `${sourceName}-clusters`, (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${sourceName}-clusters`],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource(sourceName)
        .getClusterExpansionZoom(clusterId, (err, clusterZoom) => {
          if (err) return;

          // Remember to update the store rather than the map directly...
          MapStore.easeTo(features[0].geometry.coordinates, clusterZoom);
        });
    });

    map.on("mouseenter", `${sourceName}-clusters`, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", `${sourceName}-clusters`, () => {
      map.getCanvas().style.cursor = "";
    });

    map.on("mouseenter", `${sourceName}-unclustered`, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", `${sourceName}-unclustered`, () => {
      map.getCanvas().style.cursor = "";
    });

    map.on("click", `${sourceName}-unclustered`, (e) => {
      fn ? fn(e) : null;
    });
  };

  const updateMap = (fn) => {
    if (!isMapLoaded) {
      // We can't update the source until the map is loaded, and it will load the latest data onLoad.
      return;
    }

    const map = mapInstance;

    if (map) {
      fn(map);
    }
  };

  useEffect(() => {
    MainMap.init(null, () => {
      const { mapboxgl } = window;
      if (!mapboxgl) {
        console.error("Mapbox GL JS is not loaded.");
        return;
      }

      mapboxgl.accessToken = MapboxConfig.mapboxToken;

      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: MapboxConfig.style,
        center: [lng, lat],
        zoom: zoom || 5,
      });

      setMapInstance(map);

      // disable map rotation using right click + drag
      map.dragRotate.disable();
      // disable map rotation using touch rotation gesture
      map.touchZoomRotate.disableRotation();

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
      );

      map.on("load", () => {
        const planData = MapStore.getSource("plan") || [];
        const wishlistData = MapStore.getSource("wishlist") || [];
        const atlasData = filterLocationDuplicates(AtlasStore.locations(), [
          planData,
          wishlistData,
        ]);

        /// /
        // SOURCES
        const atlasSource =
          map.getSource("atlas") ||
          map.addSource("atlas", {
            type: "geojson",
            data: locationsToGeoJSON(atlasData),
          });

        const planSource =
          map.getSource("plan") ||
          map.addSource("plan", {
            ...defaultSource,
            type: "geojson",
            data: locationsToGeoJSON(planData),
          });

        const planPathSource =
          map.getSource("plan-path") ||
          map.addSource("plan-path", {
            type: "geojson",
            data: createArcPath(planData),
          });

        const wishlistSource =
          map.getSource("wishlist") ||
          map.addSource("wishlist", {
            ...defaultSource,
            type: "geojson",
            data: locationsToGeoJSON(wishlistData),
          });

        // TODO: Disable for now.
        // const transitSource =
        //   map.getSource("transit") ||
        //   map.addSource("transit", {
        //     type: "geojson",
        //     data: NODES_URL,
        //   });

        /// /
        // LAYERS

        // TODO: Disable for now.
        // map.addLayer({
        //   id: "transit",
        //   type: "circle",
        //   source: "transit",
        //   paint: {
        //     "circle-color": "#78716c",
        //     "circle-radius": 6,
        //     "circle-stroke-width": 4,
        //     "circle-stroke-color": "#ffffff",
        //   },
        //   minzoom: 13,
        // });
        //
        // map.addLayer({
        //   id: "transit-title",
        //   type: "symbol",
        //   source: "transit",
        //   layout: {
        //     "text-field": "{name}",
        //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        //     "text-size": 12,
        //     "text-offset": [0, 0],
        //   },
        //   paint: {
        //     "text-color": "#78716c",
        //     "text-halo-color": "#ffffff",
        //     "text-halo-width": 1,
        //   },
        //   minzoom: 13,
        // });

        map.addLayer({
          id: "arc-layer",
          type: "line",
          source: "plan-path",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#1d4ed8", // blue-700
            "line-width": 2,
          },
        });

        addClusteredLayers(map, "atlas", MapboxConfig.styles.atlas, (e) => {
          MapStore.setFocusLocation(
            AtlasStore.find(e.features[0].properties.id),
          );
          e.preventDefault();
        });
        addClusteredLayers(
          map,
          "wishlist",
          MapboxConfig.styles.wishlist,
          (e) => {
            MapStore.setFocusLocation(
              AtlasStore.find(e.features[0].properties.id),
            );
            e.preventDefault();
          },
        );
        addClusteredLayers(map, "plan", MapboxConfig.styles.default, (e) => {
          MapStore.setFocusLocation(
            AtlasStore.find(e.features[0].properties.id),
          );
          e.preventDefault();
        });

        setIsMapLoaded(true);
      });
    });

    // Clean up on unmount
    // return () => map.remove();
    return () => {};
  }, []); // Run once on mount to instantiate the map, source and layer definitions.

  // Update the map when the sources change
  useEffect(() => {
    updateMap((map) => {
      // Filter down wishlist && atlas to not include any duplicates.
      const newWishlist = filterLocationDuplicates(wishlist, [plan]);
      const newAtlas = filterLocationDuplicates(AtlasStore.locations(), [
        plan,
        wishlist,
      ]);

      map.getSource("atlas").setData(locationsToGeoJSON(newAtlas));
      map.getSource("plan").setData(locationsToGeoJSON(plan));
      map.getSource("plan-path").setData(createArcPath(plan));
      map.getSource("wishlist").setData(locationsToGeoJSON(newWishlist));
    });
  }, [plan, wishlist]); // Dependency array to re-run the effect when Map store change

  // Update when the Atlas is loaded. We do this separately, as it's likely to be well before the Plan and Wishlist are loaded.
  useEffect(() => {
    updateMap((map) => {
      map
        .getSource("atlas")
        .setData(
          locationsToGeoJSON(filterLocationDuplicates(atlas, [plan, wishlist])),
        );
    });
  }, [atlas]); // Dependency array to re-run the effect when AtlasStore change

  useEffect(() => {
    updateMap((map) => {
      if (layerState === "plan") {
        map.setPaintProperty(
          "wishlist-unclustered",
          "circle-radius",
          MapboxConfig.styles.default.markerRadius * 0.75,
        );
      }

      if (layerState === "wishlist") {
        map.setPaintProperty(
          "wishlist-unclustered",
          "circle-radius",
          MapboxConfig.styles.default.markerRadius,
        );
      }
    });
  }, [layerState]);

  useEffect(() => {
    updateMap((map) => {
      map.flyTo({
        center: [lng, lat],
        zoom,
        duration: 2000,
      });
    });
  }, [lat, lng, zoom]);

  useEffect(() => {
    updateMap((map) => {
      map.resize();
    });
  }, [isSidebarOpen]);

  useEffect(() => {
    updateMap((map) => {
      // Close an existing popup if we have one
      if (popupInstances.length > 0) {
        // HACK: I'm just tracking all historic popups and removing them again
        // and again. This shouldn't really be necessary, but there seems to be
        // a race condition issue with removing them if I do them 1-by-1.
        popupInstances.forEach((popup) => popup.remove());
      }
      if (popupTarget) {
        const { geo } = popupTarget;

        // HACK: This is a bit messy, but it works for now.
        // We need to render the React component into a placeholder div, then set the DOM content of the popup to that div.
        // That way we can use React to render the popup content, and not lose the event listeners.
        const placeholder = document.createElement("div");
        const root = createRoot(placeholder);
        root.render(<LocationPopup location={popupTarget} />);

        const popup = new window.mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          maxWidth: "none",
        })
          .setLngLat([geo[0], geo[1]])
          .setDOMContent(placeholder)
          .addTo(map);

        popup.on("close", () => {
          MapStore.clearPopupTarget();
        });

        setPopupInstances(popupInstances.concat(popup));
      }
    });
  }, [popupTarget]);

  // TODO: useEffect on mapState change

  const onResetZoom = (ev) => {
    ev.stopPropagation();
    MapStore.zoomTo(null);
  };

  const renderExtraControls = () => (
    <div className="absolute left-2 top-2 z-10 flex flex-row rounded border border-slate-200 bg-white text-slate-500">
      <button
        className="px-2 py-1 text-xs hover:bg-slate-50"
        onClick={() => MapStore.setSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? (
          <>&laquo;</>
        ) : (
          <>
            &raquo;<span className="inline sm:hidden">&nbsp;Sidebar</span>
          </>
        )}
      </button>
      {isZoomed ? (
        <button
          className="border-l border-slate-200 px-2 py-1 text-xs hover:bg-slate-50"
          onClick={onResetZoom}
        >
          Reset zoom
        </button>
      ) : null}
    </div>
  );

  return (
    <div className="relative h-full w-full">
      <div ref={mapContainerRef} className="h-full w-full" />
      {renderExtraControls()}
    </div>
  );
}

export default PlannerMap;
