import React, { useState, useEffect } from "react";

function HeroText({ children, interval = 2000 }) {
  const [index, setIndex] = useState(0);
  const words = [
    "ugh",
    "spreadsheets",
    "tourist traps",
    "stress",
    "fomo",
    "boring",
  ];

  useEffect(() => {
    const tick = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, interval);

    return () => clearInterval(tick);
  }, [words, interval]);

  return (
    <span className="fade-in" key={index}>
      {words[index]}
    </span>
  );
}

export default HeroText;
