import React from "react";

function Loading(props) {
  return (
    <div className="text-center text-slate-500">
      <i className="fas fa-circle-notch fa-spin m-2 text-xl" />
      <div>Loading...</div>
    </div>
  );
}

export default Loading;
