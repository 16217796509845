import Plausible from "plausible-tracker";

let plausible = null;

if (TRUNDLE_PROFILE === "production") {
  plausible = Plausible({ domain: "trundlejapan.com" });

  // const { enableAutoPageviews } = plausible;
  // enableAutoPageviews();
}

export const plausibleEvent = (message, data) => {
  if (plausible) {
    const { trackEvent } = plausible;
    trackEvent(message, { props: data });
  }
};
