import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { isRollbar } from "./helpers";

const config = {
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    errors: {
      enabled: true, // this category is enabled by default
    },
    analytics: {
      enabled: true, // this category is enabled by default
    },
  },

  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "Respecting Your Privacy",
          description:
            "We value your privacy and use cookies to ensure you get the best experience. By clicking 'Accept all', you agree to our use of cookies. Click 'Manage preferences' for more details and control.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Accept necessary",
          showPreferencesBtn: "Manage preferences",
        },
        preferencesModal: {
          title: "Your Cookie Preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Accept necessary",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close",
          sections: [
            {
              title: "Necessary Cookies",
              description:
                "Essential for our website to function properly, these cookies cannot be turned off. They are set in response to your actions, like setting privacy preferences, logging in, or filling in forms.",
              linkedCategory: "necessary",
            },
            {
              title: "Error Reporting & Diagnostics",
              description:
                "We monitor our site to identify and solve any issues, ensuring a smooth experience for you. Your privacy is paramount to us; errors are never associated with personal details like emails. When necessary, we securely identify accounts so that we can offer better support, while keeping your details private.",
              linkedCategory: "errors",
            },
            {
              title: "Performance and Analytics",
              description:
                "To improve our website's performance and your experience, we collect anonymous, aggregate information on how you use our site. These insights help us make necessary improvements, without compromising your privacy.",
              linkedCategory: "analytics",
            },
            {
              title: "Committed to Your Privacy",
              description:
                "We're dedicated to using privacy-protecting analytics and diagnostic tools wherever possible, ensuring your browsing experience remains secure. For more details on our cookie usage and privacy practices, please visit our <a href='/privacy/'>Privacy Policy</a>.",
            },
          ],
        },
      },
    },
  },
};
const Consent = {
  init: () => {
    CookieConsent.run(config)
    if (isRollbar()) {
      Rollbar.configure({
        payload: {
          consent: CookieConsent.getUserPreferences().acceptedCategories,
        }
      });
    }
  },
  hasConsent: (term) => CookieConsent.acceptedCategory(term),
};

export default Consent;
