export const locationsToGeoJSON = (locations) => {
  const features = locations
    .map((location) => {
      if (location && location.geo) {
        const { geo, ...props } = location;

        return {
          type: "Feature",
          properties: props,
          geometry: {
            type: "Point",
            coordinates: location.geo,
          },
        };
      }
      return null;
    })
    .filter((location) => location !== null);

  return {
    type: "FeatureCollection",
    features,
  };
};

export const createArc = (startLoc, endLoc, numPoints = 100) => {
  const start = {
    lat: startLoc.geo[1],
    lng: startLoc.geo[0],
  };
  const end = {
    lat: endLoc.geo[1],
    lng: endLoc.geo[0],
  };
  const coordinates = [];
  const controlPoint = {
    lat: (start.lat + end.lat) / 2 + (end.lng - start.lng) / 8, // Adjust the divisor to control curvature
    lng: (start.lng + end.lng) / 2,
  };

  for (let i = 0; i < numPoints; i++) {
    const t = i / (numPoints - 1);
    const lat =
      (1 - t) * (1 - t) * start.lat +
      2 * (1 - t) * t * controlPoint.lat +
      t * t * end.lat;
    const lng =
      (1 - t) * (1 - t) * start.lng +
      2 * (1 - t) * t * controlPoint.lng +
      t * t * end.lng;
    coordinates.push([lng, lat]);
  }

  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates,
    },
  };
};

export const createArcPath = (locations, numPointsStep = 20) => {
  const arcs = [];
  for (let i = 0; i < locations.length - 1; i++) {
    const start = locations[i];
    const end = locations[i + 1];
    const arc = createArc(start, end, numPointsStep);
    arcs.push(arc);
  }

  return {
    type: "FeatureCollection",
    features: arcs,
  };
};

export const filterLocationDuplicates = (locations, duplicateArrays) => {
  if (locations === null) {
    return [];
  }

  const filteredCheckArray = duplicateArrays.filter(
    (duplicates) => duplicates && duplicates.length > 1,
  );

  return locations.filter(
    (location) =>
      !filteredCheckArray.some((duplicates) =>
        duplicates.some(
          (duplicate) => duplicate && location && duplicate.id === location.id,
        ),
      ),
  );
};
