import React, { useEffect } from "react";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import ActionSegment from "./segments/action";
import AddSegment from "./segments/add";
import Trip from "../../stores/trip";

function DayPlan(props) {
  const { day } = props;

  const renderMarker = (provided) => (
    <h3
      className="flex border-l-2 border-teal-600 bg-slate-50"
      {...provided.dragHandleProps}
    >
      <span
        className="
            block
            flex
            w-10
            flex-none
            flex-col
            overflow-hidden
            border-2
            border-l-0
            border-teal-600
            bg-teal-600
            text-center
          "
      >
        <span className="block h-4 bg-teal-600 text-xs text-white">DAY</span>
        <span className="block flex flex-1 flex-col justify-center bg-white font-bold">
          <span>{day.seq + 1}</span>
        </span>
      </span>
      <div className="flex w-full flex-col justify-center pl-2 text-lg font-bold">
        <span className="block w-full">
          <span className="">{day.description}</span>
          <button
            className="float-right border border-slate-50 px-1 px-1 text-base text-slate-500 hover:border-slate-200 hover:bg-white"
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this day?")) {
                Trip.deleteDay(day);
              }
            }}
          >
            <i className="fas fa-times" />
          </button>
        </span>
      </div>
    </h3>
  );

  const renderSegments = () =>
    day.segments.map((segment, index) => (
      <ActionSegment day={day} segment={segment} />
    ));

  const renderAdd = () => <AddSegment day={day} />;

  return (
    <Draggable draggableId={day.id} index={day.seq} key={day.id} type="day">
      {(provided) => (
        <div
          className="mb-4"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {renderMarker(provided)}
          <div className="mb-4 bg-white shadow-md">
            <Droppable droppableId={day.id} key={day.seq} type="segment">
              {(dropProvided) => (
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                >
                  {renderSegments()}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
            {renderAdd()}
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default DayPlan;
