// import "./lib/polyfills.js";

import React from "react";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import MainMap from "./maps/main";
// import HeroMap from "./maps/hero.js";
import Consent from "./lib/consent";

import WishlistHero from "./components/wishlist/hero";
import WishlistSidebar from "./components/wishlist/sidebar";
import Login from "./components/login";
// import AccountDropdown from "./components/nav/account_dropdown.jsx";
// import TripDropdown from "./components/nav/trip_dropdown.jsx";
import AccountNav from "./components/nav/account_nav";
import TripTool from "./components/trip/tool";
import AccountCentre from "./components/account/account";
import Notice from "./components/upsell/notice";
import HeroText from "./components/home/hero_text";

Consent.init();
Modal.setAppElement("#root");

function init(srootector, fn) {
  const el = document.querySelector(srootector);
  if (el) {
    const root = createRoot(el);
    fn(root, el);
  }
}

init("#wishlist-hero", (root, el) => {
  root.render(
    <WishlistHero
      locationId={el.attributes["data-id"].value}
      title={el.attributes["data-title"].value}
    />,
    root,
  );
});
init("#wishlist-sidebar", (root, el) => {
  root.render(
    <WishlistSidebar
      locationId={el.attributes["data-id"].value}
      title={el.attributes["data-title"].value}
    />,
    root,
  );
});
init("#account-nav", (root) => {
  root.render(<AccountNav />);
});
init("#login-form", (root) => {
  root.render(<Login ctaText="Sign Up" hideHeader="true" />);
});
init("#account-centre", (root) => {
  root.render(<AccountCentre />);
});
init("#trip-tool", (root, el) => {
  root.render(<TripTool tab={el.attributes["data-tab"].value} />);
});
init("#notice", (root) => {
  root.render(<Notice />);
});
init("#hero-text", (root) => {
  root.render(<HeroText />);
});

if (TRUNDLE_PROFILE !== "test") {
  const mainMap = document.querySelector(".node-map");
  if (mainMap) {
    MainMap.init(mainMap);
  }
}
