import React, { createRef } from "react";
import Trip from "../../../../stores/trip";
import MenuSection from "./section";

export default function NoteSection(props) {
  const { day, doCloseParent } = props;
  const noteRef = createRef();

  const saveNote = (ev) => {
    ev.stopPropagation();
    const segment = {
      type: "Note",
      description: noteRef.current.value,
    };

    Trip.addToDay(day, segment);
    doCloseParent();
  };

  return (
    <MenuSection icon="fa-pen-alt" text="Note" doCloseParent={doCloseParent}>
      <textarea
        ref={noteRef}
        className="h-24 w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <button
        className="float-right mt-1 rounded bg-slate-50 px-2 py-2 text-slate-900"
        onClick={saveNote}
      >
        Save
      </button>
    </MenuSection>
  );
}
