import { create } from "zustand";
import { isDeviceSmall } from "../lib/helpers";
import Atlas from "./atlas";
import Wishlist from "./wishlist";
import Trip from "./trip";

const initialZoom = {
  lat: "34.6937",
  lng: "135.5023",
  zoom: "5",
  isZoomed: false,
};

const useMapStore = create((set) => ({
  state: "plan",
  isSidebarOpen: true,
  plan: [],
  wishlist: [],
  popupTarget: null,
  isPitched: false,
  ...initialZoom,
}));

const unsubscribeTrip = Trip.useStore.subscribe((state, _oldState) => {
  MapStore.refresh();
});
const unsubscribeAtlas = Atlas.useStore.subscribe((state, _oldState) => {
  MapStore.refresh();
});
const unsubscribeWishlist = Wishlist.useStore.subscribe((state, _oldState) => {
  MapStore.refresh();
});

const MapStore = {
  useStore: useMapStore,

  zoomTo: (location) => {
    if (location === null) {
      useMapStore.setState(initialZoom);
    } else {
      useMapStore.setState({
        lat: location.geo[1],
        lng: location.geo[0],
        zoom: 14,
        isZoomed: true,
      });
    }
  },

  easeTo: (geo, zoom) => {
    useMapStore.setState({
      lat: geo[1],
      lng: geo[0],
      zoom,
      isZoomed: true,
    });
  },

  setTab: (tab) => {
    const update = {
      state: tab,
    };
    if (isDeviceSmall()) {
      update.isSidebarOpen = true;
    }

    useMapStore.setState(update);
  },

  setSidebarOpen: (isOpen) => {
    useMapStore.setState({ isSidebarOpen: isOpen });
  },

  setIsPitched: (isPitched) => {
    useMapStore.setState({ isPitched });
  },

  setSource: (source, locations) => {
    useMapStore.setState({ [source]: locations });
  },

  getSource: (source) => {
    const state = MapStore.useStore.getState();
    return state[source];
  },

  setFocusLocation: (location) => {
    MapStore.setPopupTarget(location);
    MapStore.zoomTo(location);
  },

  setPopupTarget: (location) => {
    const update = { popupTarget: location };
    if (isDeviceSmall()) {
      update.isSidebarOpen = false;
    }
    useMapStore.setState(update);
  },

  clearPopupTarget: () => {
    useMapStore.setState({ popupTarget: null });
  },

  refresh: () => {
    if (Atlas.useStore.getState().isLoaded) {
      MapStore._refreshPlan();
      MapStore._refreshWishlist();
    }
  },

  _refreshPlan: () => {
    const state = useMapStore.getState();
    const tripState = Trip.useStore.getState();

    if (tripState.isLoaded) {
      const { days } = tripState;
      const locations = days.reduce((acc, day) => {
        day.segments.forEach((segment) => {
          if (segment.locationId) {
            acc.push(Atlas.find(segment.locationId));
          }
        });
        return acc;
      }, []);

      MapStore.setSource("plan", locations);
    }
  },

  _refreshWishlist: () => {
    const state = useMapStore.getState();
    const wishlistState = Wishlist.useStore.getState();

    if (wishlistState.isLocalLoaded) {
      const { initialIds } = wishlistState;
      const planIds = state.plan.map((location) => location.id);

      const locations = initialIds
        .filter((id) => !planIds.includes(id))
        .map((id) => Atlas.find(id));

      MapStore.setSource("wishlist", locations);
    }
  },
};

export default MapStore;
