import { plausibleEvent } from "./plausible";

export const isRollbar = () => typeof Rollbar !== "undefined";

export const isDeviceSmall = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth < 640; // twcss's sm breakpoint
  }
  return false;
};

export const reportEvent = (message = "pageview", data = {}) => {
  if (TRUNDLE_PROFILE === "staging") {
    console.log("EVENT", message, data);
  }
  plausibleEvent(message, data);
};

export const reportError = (message, data) => {
  if (isRollbar()) {
    Rollbar.error(message, data);
  }
  if (TRUNDLE_PROFILE === "staging") {
    console.error("ERROR", message, data);
  }
};
