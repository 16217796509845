import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";

function TrundleModal(props) {
  const { children, onClose } = props;
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup function
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const doClose = () => {
    setIsOpen(false);
    onClose ? onClose() : null;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        doClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const bgClick = (event) => {
    if (!(modalRef.current && modalRef.current.contains(event.target))) {
      doClose();
    }
  };

  return (
    isOpen && (
      <Modal
        isOpen
        onRequestClose={doClose}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-10 py-20 z-40"
        className="relative z-50 outline-none"
      >
        <div className="border-2 border-teal-600 bg-white text-slate-700 shadow-lg lg:max-w-3xl">
          <button
            className="absolute right-0 top-0 block aspect-square h-10 bg-teal-600 text-white hover:bg-teal-800 focus:bg-teal-800 active:bg-teal-800 sm:mr-0 sm:mt-0"
            aria-label="Close"
            onClick={doClose}
          >
            <i className="fas fa-times" />
          </button>
          {children}
        </div>
      </Modal>
    )
  );
}

export default TrundleModal;
