import React from "react";
import Auth from "../../stores/auth.js";

function Notice(props) {
  const { isAuth } = Auth.useStore();

  if (isAuth) {
    return <></>;
  }
  return (
    <a
      href="/my/trip/"
      className="
        block
        border-b-2
        border-teal-400
        bg-teal-600
        px-4
        py-2
        text-center
        text-sm
        text-white
        hover:bg-teal-700
        hover:underline
        sm:border-t-2
      "
    >
      Trundle Planner has arrived!{" "}
      <span className="underline">Try it for free</span> to start building your
      perfect Japan itinerary!
    </a>
  );
}

export default Notice;
