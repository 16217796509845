import React, { useEffect } from "react";
import Auth from "../../stores/auth.js";

const IdeasNav = (props) => {
  const { isAuth, session } = Auth.useStore();

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]); // Empty dependency array ensures it runs once on mount

  const renderBadge = () => {
    return <></>;
    return (
      <span
        class="
          absolute
          top-0
          right-0
          transform
          translate-x-0
          translate-y-1
          bg-indigo-600
          text-white
          text-xs
          rounded-full
          px-1
        "
      >
        3
      </span>
    );
  };

  return (
    <a
      href="/my/suggestions/"
      class="
      relative
      inline-flex
      grow-0
      items-center
      px-4
      align-middle
      break-inside-avoid
    "
    >
      <span class="">Ideas</span>
      {renderBadge()}
    </a>
  );
};

export default IdeasNav;
