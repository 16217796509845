import React, { useEffect, useState } from "react";
import Auth from "../../stores/auth.js";
import Wishlist from "../../stores/wishlist.js";
import Modal from "../modal.jsx";
import SignupPitch from "../upsell/signup_pitch.jsx";
import { reportEvent } from "../../lib/helpers.js";

function WishlistHero(props) {
  const { locationId, title } = props;
  const { isSyncing } = Wishlist.useStore();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onList = () => Wishlist.isOnList(locationId);

  const handleClick = () => {
    const newState = !onList();

    Wishlist.setOnList(locationId, newState);

    if (newState && !Auth.isAuth() && Wishlist.count() >= 3) {
      reportEvent("show_pitch", { view: "hero", id: locationId });
      setIsPopupVisible(true);
    }
  };

  const stateClass = () => {
    if (onList()) {
      return "border-teal-600 bg-teal-50 text-teal-600";
    }
    return "border-slate-900 bg-white";
  };

  const iconClass = () => {
    if (isSyncing) {
      return "fas fa-circle-notch fa-spin";
    }
    if (onList()) {
      return "fas fa-check";
    }
    return "fas fa-plus";
  };

  const message = () => {
    if (onList()) {
      return "ADDED";
    }
    return "ADD";
  };

  return (
    <>
      <span className="m-2 block h-32 w-1/6">
        <button
          className={`block h-32 cursor-pointer border-2 p-4 shadow-md ${stateClass()}`}
          onClick={handleClick}
        >
          <div className="mb-2 text-sm font-thin">WISHLIST</div>
          <span>
            <i className={iconClass()} />
            <br />
            {message()}
          </span>
        </button>
      </span>
      {isPopupVisible && (
        <Modal>
          <SignupPitch title={title} />
        </Modal>
      )}
    </>
  );
}

export default WishlistHero;
