import React, { useState } from "react";
import BaseSegment, { TextContent } from "./base";

const DEBUG = false;

function TransitSegment(props) {
  const { day, segment, transitRoute } = props;
  const transit = transitRoute[0];

  const renderIcon = (mode, step) => {
    if (mode === "board" || mode === "transfer") {
      return "fa-circle";
    }
    if (mode === "disembark") {
      return "fa-stop-circle";
    }
    if (mode === "walk" || mode === "Walk") {
      return "fa-walking";
    }
    if (mode === "train" || mode === "Train") {
      return "fa-train";
    }
    if (mode === "subway" || mode === "Subway") {
      return "fa-subway";
    }
    if (step && step.line && step.line.mode) {
      return renderIcon(step.line.mode);
    }
    if (DEBUG) {
      console.log(mode);
    }
  };

  const renderLineIcon = (step) => {
    const style = {};
    if (step.line && step.line.color) {
      style.color = step.line.color;
    }

    const icon = renderIcon(step.mode, step);

    return (
      <div className="w-8 flex-none text-center">
        {icon && <i className={`fas ${icon}`} style={style} />}
      </div>
    );
  };

  const renderMenu = () => {
    const steps = transit.data.path
      .filter((step) => DEBUG || !step.skip)
      .map((step, index) => (
        <div className="mb-3 flex items-baseline text-sm">
          {renderLineIcon(step)}
          <span className="flex-1">{step.description}</span>
          {(DEBUG || step.mode === "walk") && (
            <span className="mr-1 text-xs text-slate-500">
              ({step.duration}min)
            </span>
          )}
        </div>
      ));

    return <div>{steps}</div>;
  };

  const humanDistance = () => {
    if (transit.distance >= 800) {
      if ((transit.distance / 1000) % 1.0 === 0) {
        return `${(transit.distance / 1000).toFixed(0)}km`;
      }
      return `${(transit.distance / 1000).toFixed(1)}km`;
    }
    return `${transit.distance}m`;
  };

  const humanDuration = () => {
    if (transit.duration < 50) {
      return `${transit.duration}min`;
    }
    const halves = Math.ceil(transit.duration / 30);
    return `${(halves / 2).toFixed(1)}hr`;
  };

  const icon = () => {};

  if (segment.transitStatus === "loaded") {
    if (transit.data.path && transit.data.path.length > 0) {
      return (
        <BaseSegment
          style={{ icon: renderIcon(transit.type) }}
          renderMenu={renderMenu}
        >
          <TextContent className="text-slate-600">
            {transit.description || transit.type}{" "}
            <i className="fas fa-walking" /> {humanDistance()}{" "}
            <span>({humanDuration()})</span>
          </TextContent>
        </BaseSegment>
      );
    }
    return (
      <BaseSegment style={{ icon: "fa-exclamation-triangle" }}>
        <TextContent className="text-slate-600">
          Unable to find a route
        </TextContent>
      </BaseSegment>
    );
  }
  if (segment.transitStatus === "loading") {
    return <div>LOADING...</div>;
  }
}

export default TransitSegment;
