import React, { useEffect } from "react";
import Auth from "../../stores/auth.js";
import Login from "../login.jsx";

const AccountCentre = (props) => {
  const { isAuth, isLoading, session } = Auth.useStore();

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]); // Empty dependency array ensures it runs once on mount

  const displayName = () => {
    if (
      session.user.user_metadata &&
      session.user.user_metadata.name &&
      session.user.user_metadata.name.length > 0
    ) {
      return session.user.user_metadata.full_name;
    } else {
      return session.user.email;
    }
  };

  if (isAuth && session) {
    return (
      <div className="bg-teal-100 border border-teal-200 rounded-md p-2 m-2">
        You are signed in as {displayName()}.
      </div>
    );
  } else {
    if (isLoading) {
      return <div>Loading...</div>;
    } else {
      return <Login />;
    }
  }
};

export default AccountCentre;
