import React, { createRef } from "react";
import Trip from "../../../../stores/trip";
import MenuSection from "./section";

export default function RelocateSection(props) {
  const { day, doCloseParent } = props;
  const segmentRef = createRef();

  const saveSegment = (ev) => {
    ev.stopPropagation();
    const segment = {
      type: "Relocate",
      description: `Travel to ${segmentRef.current.value}`,
      data: { destinationRegion: segmentRef.current.value },
    };

    Trip.addToDay(day, segment);
    doCloseParent();
  };

  return (
    <MenuSection
      icon="fa-exchange-alt"
      text="Depart To..."
      doCloseParent={doCloseParent}
    >
      <input
        type="text"
        ref={segmentRef}
        className="w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        placeholder="Region (eg. Tokyo)"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <button
        className="float-right mt-1 rounded bg-slate-50 px-2 py-2 text-slate-900"
        onClick={saveSegment}
      >
        Save
      </button>
    </MenuSection>
  );
}
