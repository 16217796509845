const MapboxConfig = {
  mapboxToken:
    "pk.eyJ1IjoianN0aXJrIiwiYSI6ImNsczQ2YXdhODBmZzYybnJ4MGFlbXkydDMifQ.q3SfGsKPARCGN5ZbnD7VkQ",
  style: "mapbox://styles/jstirk/cjfftqzea03jb2rpd5b9w125z",
  styles: {
    legacy: {
      borderWidth: 2,
      clusterBackground: "#0d9488",
      clusterBorder: "#0f766e",
      clusterText: "#fff",
      markerBackground: "#0d9488",
      markerBorder: "#0f766e",
      markerText: "#111",
      markerHaloColor: "#fff",
    },
    default: {
      textMinzoom: 5,
      borderWidth: 2,
      markerRadius: 12,
      clusterBackground: "#0d9488",
      clusterBorder: "#ffffff",
      clusterText: "#fff",
      clusterTextSize: 14,
      markerBackground: "#0d9488",
      markerBorder: "#ffffff",
      markerText: "#111",
      markerTextSize: 16,
      markerHaloColor: "#fff",
    },
    wishlist: {
      textMinzoom: 5,
      markerMinzoom: 2,
      borderWidth: 2,
      clusterBackground: "#facc15",
      clusterBorder: "#ffffff",
      clusterText: "#111",
      markerBackground: "#facc15",
      markerBorder: "#ffffff",
      markerText: "#111",
      markerHaloColor: "#fff",
    },
    atlas: {
      textMinzoom: 12,
      markerMinzoom: 8,
      markerRadius: 10,
      borderWidth: 1,
      clusterBackground: "#115e59",
      clusterBorder: "#ffffff",
      clusterText: "#ffffff",
      clusterTextSize: 12,
      clusterMaxzoom: 7,
      markerBackground: "#115e59",
      markerBorder: "#ffffff",
      markerText: "#111",
      markerTextSize: 14,
      markerHaloColor: "#fff",
    },
  },
};

export default MapboxConfig;
