import React, { createRef, useEffect, useState } from "react";
import Trip from "../../stores/trip.js";
import Auth from "../../stores/auth.js";
import Wishlist from "../../stores/wishlist.js";
import MapStore from "../../stores/map";
import TripPlan from "./plan.jsx";
import TripWishlist from "./wishlist.jsx";
import PlannerMap from "../../maps/planner_map.jsx";
import Login from "../login.jsx";

function TripTool(props) {
  const { isInitialized, isAuth } = Auth.useStore();
  const { days } = Trip.useStore();
  const { initialIds } = Wishlist.useStore();
  const { state: showTab, isSidebarOpen } = MapStore.useStore();

  const setTab = (tab) => MapStore.setTab(tab);
  const setSidebarOpen = (isOpen) => MapStore.setSidebarOpen(isOpen);

  // On initial render, set the tab to the one specified in the props.
  useEffect(() => {
    MapStore.setTab(props.tab);
  }, []);

  const renderButtons = () => {
    const dayCount = days ? days.length : "...";
    const wishlistCount = initialIds ? initialIds.length : "...";

    return (
      <div className="grid w-full grid-cols-4 gap-0 border-b-2 border-slate-200 bg-white text-sm sm:grid-cols-3">
        <button
          className="border-r border-slate-200 px-2 py-1 text-teal-900"
          onClick={() => setTab("plan")}
        >
          <i className="fas fa-map mr-1" />
          &nbsp;Plan
          <div className="text-xs">{dayCount} days</div>
        </button>
        <button
          className="border-r border-slate-200 bg-indigo-50 px-2 py-1 text-indigo-900"
          onClick={() => setTab("ideas")}
        >
          <i className="fas fa-lightbulb mr-1" />
          &nbsp;Ideas
          <div className="text-xs">0 items</div>
        </button>
        <button
          className="border-r border-slate-200 bg-yellow-50 px-2 py-1 text-yellow-900 sm:border-0"
          onClick={() => setTab("wishlist")}
        >
          Wishlist
          <div className="text-xs">{wishlistCount} items</div>
        </button>
        <button
          className="bg-teal-50 px-2 py-1 text-teal-900 sm:hidden"
          onClick={() => setSidebarOpen(false)}
        >
          <i className="fas fa-map-marked-alt mr-1" />
          Map
          <div className="text-xs" />
        </button>
        {false ? (
          <button
            className="bg-amber-100 px-2 py-1 text-amber-900"
            onClick={() => setTab("budget")}
          >
            <i className="fas fa-yen mr-1" />
            Budget
            <div className="text-xs">$3,520</div>
          </button>
        ) : null}
      </div>
    );
  };

  const renderTab = () => {
    if (isInitialized && !isAuth && showTab !== "wishlist") {
      return (
        <div className="min-h-dvh px-2 sm:min-h-96">
          <div className="mb-2 border-2 border-teal-600 bg-teal-50 p-1 text-center text-teal-800">
            Create a free Trundle account to use the Trip Planner.
          </div>

          <Login
            hideHeader
            redirectToPath={window.location.pathname}
            ctaText="Sign Up"
          />
        </div>
      );
    }

    if (showTab === "plan") {
      return <TripPlan />;
    }
    if (showTab === "ideas") {
      return (
        <div className="w-full">
          <div className="m-2 border-2 border-indigo-600 bg-indigo-50 p-2 text-indigo-900">
            Coming soon.
          </div>
        </div>
      );
    }
    if (showTab === "wishlist") {
      return <TripWishlist />;
    }
    if (showTab === "budget") {
      return <div className="w-full">budget</div>;
    }
  };

  return (
    <>
      <div
        className={`relative min-h-dvh bg-slate-50 sm:min-h-96 sm:overflow-y-auto sm:border-slate-300
            ${
              isSidebarOpen
                ? "w-full sm:w-96 sm:border-r-2"
                : "w-2 bg-slate-600"
            }
        `}
      >
        {isSidebarOpen && (
          <>
            <div className="flex-0 z-20 w-full bg-white sm:fixed sm:w-96 sm:border-r-2 sm:border-slate-300">
              {renderButtons()}
            </div>
            <div
              className={`flex-1 bg-slate-50 px-1 pt-2 sm:mt-12 
            `}
            >
              {renderTab()}
            </div>
          </>
        )}
      </div>
      <div className={`${isSidebarOpen ? "hidden" : "block"} flex-1 sm:block`}>
        <PlannerMap />
      </div>
    </>
  );
}

export default TripTool;
