import React, { createRef } from "react";
import Trip from "../../../../stores/trip";
import MenuSection from "./section";

export default function AccommodationSection(props) {
  const { day, doCloseParent } = props;
  const nameRef = createRef();
  const nightsRef = createRef();
  const addressRef = createRef();

  const saveSegment = (ev) => {
    ev.stopPropagation();
    const segment = {
      type: "Accommodation",
      description: `Stay at ${nameRef.current.value}`,
      data: {
        name: nameRef.current.value,
        nights: nightsRef.current.value,
        address: addressRef.current.value,
      },
    };

    Trip.addToDay(day, segment);
    doCloseParent();
  };

  return (
    <MenuSection
      icon="fa-hotel"
      text="Accommodation"
      doCloseParent={doCloseParent}
    >
      <input
        type="text"
        ref={nameRef}
        className="w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        placeholder="Accommodation name"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <label htmlFor="nights" className="mt-2">
        Nights
      </label>
      <input
        type="text"
        ref={nightsRef}
        className="w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <label htmlFor="address" className="mt-2">
        Address
      </label>
      <textarea
        ref={addressRef}
        name="address"
        className="h-24 w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <button
        className="float-right mt-1 rounded bg-slate-50 px-2 py-2 text-slate-900"
        onClick={saveSegment}
      >
        Save
      </button>
    </MenuSection>
  );
}
