import React, { useEffect } from "react";
import Auth from "../stores/auth.js";
import { reportEvent } from "../lib/helpers.js";

function Login(props) {
  const emailRef = React.createRef();
  const { isAuth, isLoading, isEmailSent, session } = Auth.useStore();
  const { redirectToPath, message, hideHeader, ctaText } = props;

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]); // Empty dependency array ensures it runs once on mount

  const displayCta = () => {
    if (ctaText && ctaText !== "") {
      return ctaText;
    }
    return "Sign In";
  };

  const buttonText = () => {
    if (isLoading) {
      return <i className="fas fa-spinner fa-spin" />;
    }
    return displayCta();
  };

  if (isAuth && session) {
    return (
      <div className="block border-2 border-teal-600 bg-white p-2 text-center">
        <div className="font-bold text-teal-600">Welcome back!</div>
        <div>
          <a href="/my/trip/" className="underline">
            Your Trip plan is waiting for you &rarr;
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="md:w-xl m-auto text-center md:max-w-xl">
      {!hideHeader && (
        <h1 className="mb-2 text-xl font-bold">{displayCta()}</h1>
      )}
      {isEmailSent ? (
        <div className="m-2 border-2 border-teal-600 bg-teal-600 p-2 font-bold text-white">
          We've sent you an email containing a sign in link.
        </div>
      ) : (
        ""
      )}

      <div className="flex justify-center gap-x-0">
        <div className="focus-container mx-2 flex w-full">
          <input
            type="email"
            aria-label="Email"
            required=""
            id="fieldEmail"
            maxLength="200"
            placeholder="your@email.com"
            ref={emailRef}
            className="
          qa-input-email
          z-10
          flex-1
          appearance-none
          rounded-none
          rounded-l-full
          border
          border-r-0
          border-slate-300
          px-4
          py-2
          text-base
          text-slate-900
          placeholder-slate-500
          focus:outline-none
        "
          />
          <button
            type="submit"
            className="
          flex-0
          group
          z-20
          -ml-5
          inline-flex
          w-24
          items-center
          justify-center
          rounded-full
          bg-teal-600
          px-4
          py-2
          text-sm
          font-semibold
          text-white
          hover:bg-teal-800
          focus:outline-none
          focus-visible:outline-2
          focus-visible:outline-offset-2
          focus-visible:outline-teal-900
          active:bg-teal-900
        "
            onClick={() => {
              if (!isLoading) {
                reportEvent("attempt_login", { method: "email" });
                Auth.loginEmail(emailRef.current.value, redirectToPath);
              }
            }}
          >
            {" "}
            {buttonText()}
          </button>
        </div>
      </div>
      {TRUNDLE_PROFILE === "staging" && (
        <>
          <div className="my-2 text-center text-sm text-slate-400">
            - or {displayCta()} with -
          </div>
          <div className="mt-1 flex justify-center text-center">
            <button
              className="
          mr-1
        block
        h-12
        rounded
        border
        border-slate-200
        bg-white
        px-2
        py-1
        text-xl
        hover:border-teal-600
        hover:bg-teal-50
        hover:text-teal-800
        focus:border-teal-600
        focus:bg-teal-50
        focus:text-teal-800
      "
              onClick={() => {
                Auth.loginGoogle(redirectToPath);
              }}
            >
              <i className="fab fa-google" />{" "}
              <span className="text-sm">Google</span>
            </button>
            <button
              className="
          block
        h-12
        rounded
        border
        border-slate-200
        bg-white
        px-2
        py-1
        text-xl
        hover:border-teal-600
        hover:bg-teal-50
        hover:text-teal-800
        focus:border-teal-600
        focus:bg-teal-50
        focus:text-teal-800
      "
              onClick={() => {
                Auth.loginFacebook(redirectToPath);
              }}
            >
              <i className="fab fa-facebook" />{" "}
              <span className="text-sm">Facebook</span>
            </button>
          </div>
        </>
      )}
      <div className="text-center">
        <div className="mt-1 text-xs text-slate-400">
          Continuing agrees to our
          <a href="/terms/" className="mx-1 underline" target="_blank">
            Terms
          </a>
          and
          <a href="/privacy/" className="mx-1 underline" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
}

export default Login;
