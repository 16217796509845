import React, { useEffect, useState } from "react";
import SignupPitch from "../upsell/signup_pitch.jsx";
import Auth from "../../stores/auth.js";
import Wishlist from "../../stores/wishlist.js";
import Atlas from "../../stores/atlas.js";
import MapStore from "../../stores/map";
import Modal from "../modal.jsx";
import WishlistItem from "./wishlist_item.jsx";
import Loading from "./loading";
import { reportEvent } from "../../lib/helpers.js";

function TripWishlist(props) {
  const { skipPitch, onMapSource } = props;
  const { isAuth } = Auth.useStore();
  const { isLoaded: isAtlasLoaded } = Atlas.useStore();
  const { isPitched } = MapStore.useStore();
  const {
    isLocalLoaded: isWishlistLoaded,
    localItems,
    initialIds,
  } = Wishlist.useStore();

  const loadInitialAuthState = Auth.status;
  const loadInitialWishlistState = Wishlist.init;
  const loadInitialAtlasState = Atlas.init;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]);
  useEffect(() => {
    loadInitialWishlistState();
  }, [loadInitialWishlistState]);
  useEffect(() => {
    loadInitialAtlasState();
  }, [loadInitialAtlasState]);

  const renderSignupPitch = () => {
    if (skipPitch || isPitched) {
      return null;
    }

    if (isAuth === false && initialIds.length > 0) {
      reportEvent("show_pitch", { view: "wishlist" });
      return (
        <Modal onClose={() => MapStore.setIsPitched(true)}>
          <SignupPitch />
        </Modal>
      );
    }
  };

  const renderWishlist = () => {
    const regions = initialIds.reduce((acc, id) => {
      const item = Atlas.find(id);
      if (item) {
        acc[item.region] = acc[item.region] || [];
        acc[item.region].push(item);
      }

      return acc;
    }, {});

    const els = Object.keys(regions)
      .sort()
      .map((region) => {
        const regionEls = regions[region]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((item) => (
            <WishlistItem id={item.id} isOnList={Wishlist.isOnList(item.id)} />
          ));
        return (
          <>
            <h2 className="mb-2 mt-8 text-lg font-bold">{region}</h2>
            {regionEls}
          </>
        );
      });

    return <div className="space-y-2">{els}</div>;
  };

  const renderEmptyWishlist = () => {
    if (initialIds.length === 0) {
      return (
        <div className="border-2 border-slate-300 text-center text-slate-400">
          <div className="my-8">
            Add some places to your wishlist to get started!
            <div>
              <a className="text-teal-600 underline" href="/japan/">
                Explore Japan
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  if (onMapSource) {
    onMapSource(initialIds.map((id) => Atlas.find(id)));
  }

  return (
    <div className="">
      {!isAtlasLoaded || !isWishlistLoaded ? (
        <Loading />
      ) : (
        <>
          {renderSignupPitch()}
          {renderWishlist()}
          {renderEmptyWishlist()}
        </>
      )}
    </div>
  );
}

export default TripWishlist;
