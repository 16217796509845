import React, { useState, createRef } from "react";

export function TextContent(props) {
  const { children, className } = props;
  return <div className={`py-4 pl-2 ${className || ""}`}>{children}</div>;
}

function BaseSegment(props) {
  const { renderMenu, children, style } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const elRef = createRef();

  const doClick = () => {
    if (renderMenu) {
      const initialOpen = isMenuOpen;

      setMenuOpen(!isMenuOpen);
      if (elRef.current && !initialOpen) {
        elRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const doClose = () => {
    setMenuOpen(false);
  };

  return (
    <div
      ref={elRef}
      className={`relative z-0 flex flex-row border-l-2 bg-white ${
        isMenuOpen
          ? `${style.activeBorderStyle || "border-teal-700"} border-b-4`
          : style.idleBorderStyle || "border-teal-300"
      }`}
      onClick={doClick}
    >
      <div
        className={`block flex w-10 flex-none flex-col justify-center py-4 text-center ${
          isMenuOpen
            ? style.activeBannerStyle || "bg-teal-600 text-white"
            : style.idleBannerStyle || "bg-teal-100 text-teal-600"
        }`}
      >
        <i className={`fas ${style.icon || "fa-map-marker-alt"}`} />
      </div>
      <div className="flex flex-1 flex-col justify-center">
        <div className="flex w-full text-sm">{children}</div>
        {renderMenu && isMenuOpen ? renderMenu(doClose) : null}
      </div>
    </div>
  );
}

export default BaseSegment;
