import React, { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import BaseSegment, { TextContent } from "./base";
import Trip from "../../../stores/trip";
import MapStore from "../../../stores/map";
import TransitSegment from "./transit";

function ActionSegment(props) {
  const { day, segment, children } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);

  const typeSettings = {
    Location: {
      icon: "fa-ticket-alt",
      render: () =>
        segment.location ? (
          <>
            <img
              className="
                hidden aspect-video h-16
          flex-none
          object-cover
          sm:inline-block
        "
              loading="lazy"
              alt={segment.location.title}
              src={segment.location.thumbnail_url}
            />
            <h4 className="flex h-full flex-1 flex-col justify-center py-4 pl-2 sm:py-0">
              <span className="block">
                {false ? (
                  <span className="mr-2 block py-1 text-xs font-bold text-teal-800">
                    9:00 AM
                  </span>
                ) : null}
                <span className="block font-bold">
                  {segment.location.title}
                </span>
                <span className="ml-2 hidden rounded-full bg-teal-100 px-2 py-1 text-sm text-teal-800">
                  ~&yen;1500
                </span>
              </span>
            </h4>
          </>
        ) : null,
    },
    Flight: {
      icon: "fa-plane",
      render: () => (
        <TextContent className="text-slate-600">
          {segment.description && segment.description !== ""
            ? segment.description
            : "Flight"}
        </TextContent>
      ),
    },
    Relocate: {
      icon: "fa-exchange-alt",
      render: () => (
        <TextContent className="text-slate-600">
          {segment.description}
        </TextContent>
      ),
    },
    Walk: {
      icon: "fa-walking",
      render: () => (
        <TextContent className="text-slate-600">
          {segment.description && segment.description !== ""
            ? segment.description
            : "Walk"}
        </TextContent>
      ),
    },
    Note: {
      icon: "fa-pen-alt",
      render: () => (
        <TextContent className="whitespace-pre-line pr-2 text-slate-600">
          {segment.description && segment.description !== ""
            ? segment.description
            : "Note"}
        </TextContent>
      ),
    },
    Accommodation: {
      icon: "fa-hotel",
      render: () => (
        <TextContent className="text-slate-600">
          {segment.description} ({segment.data.nights} nights)
          <div className="whitespace-pre-line pr-2 pt-1 text-xs">
            {segment.data.address}
          </div>
        </TextContent>
      ),
    },
    URL: {
      icon: "fa-link",
      render: () => (
        <TextContent className="pr-2 text-slate-600">
          {segment.description}
          <a
            href={segment.data.url}
            target="_blank"
            rel="noreferrer"
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <i className="fas fa-external-link-alt ml-1" />
          </a>
        </TextContent>
      ),
    },
  };

  const { icon, render } = typeSettings[segment.type];

  const onLocation = (ev) => {
    ev.stopPropagation();

    MapStore.setFocusLocation(segment.location);
  };

  const onDelete = (ev) => {
    ev.stopPropagation();

    const confirmText = segment.locationId
      ? `Are you sure you want to delete ${segment.location.title}?`
      : "Are you sure you want to delete this segment?";

    const confirmed = window.confirm(confirmText);

    if (confirmed) {
      Trip.removeFromDay(day.seq, segment);
    }
  };

  const renderMenu = () => (
    <div>
      <div className="">
        <div className="grid grid-cols-3 text-sm">
          <button
            className=" border-r border-slate-200 bg-teal-50 px-2 py-4 text-teal-800"
            onClick={() => window.open(segment.location.permalink, "_blank")}
          >
            <i className="fas fa-info-circle mr-1" />
            About
          </button>
          <button
            className=" border-r border-slate-200 bg-teal-50 px-2 py-4 text-teal-800"
            onClick={onLocation}
          >
            <i className="fas fa-map-marked-alt mr-1" />
            Map
          </button>
          <button
            className=" bg-red-50 px-2 py-4 text-red-800"
            onClick={onDelete}
          >
            <i className="fas fa-times mr-1" />
            Delete
          </button>
        </div>
        {false ? <div className="m-2 text-sm" /> : null}
      </div>
    </div>
  );

  return (
    <Draggable
      draggableId={segment.id}
      index={segment.seq}
      key={segment.id}
      type="segment"
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {segment.transitRoute ? (
            <TransitSegment
              day={day}
              segment={segment}
              transitRoute={segment.transitRoute}
            />
          ) : null}
          <BaseSegment style={{ icon }} renderMenu={renderMenu}>
            {render()}
          </BaseSegment>
        </div>
      )}
    </Draggable>
  );
}

export default ActionSegment;
