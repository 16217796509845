import { create } from "zustand";
import supabase from "../lib/supabase";
import { isRollbar, reportError } from "../lib/helpers";
import Consent from "../lib/consent";

const useAuthStore = create((set) => ({
  isAuth: null,
  isError: null,
  isEmailSent: null,
  isInitialized: false,
  isLoading: false,
  session: null,
}));

const redirectTo = (path) =>
  path !== undefined ? WEBSITE_ROOT + path : `${WEBSITE_ROOT}/my/trip/`;

export const isAuth = () => Auth.useStore.getState().isAuth || false;

const Auth = {
  useStore: useAuthStore,

  isAuth: () => useAuthStore.getState().isAuth || false,

  status: async () => {
    const state = useAuthStore.getState();
    if (state.isAuth !== null) {
      return;
    }

    if (!state.isLoading) {
      useAuthStore.setState({ isLoading: true });

      const { data, error } = await supabase.auth.getSession();
      if (data && data.session) {
        useAuthStore.setState({
          isLoading: false,
          isInitialized: true,
          isAuth: true,
          session: data.session,
          isError: false,
        });

        if (isRollbar() && Consent.hasConsent("errors")) {
          Rollbar.configure({
            payload: {
              person: {
                id: data.session.user.id,
              },
            },
          });
        }
      } else {
        useAuthStore.setState({
          isLoading: false,
          isInitialized: true,
          isAuth: false,
          session: null,
          isError: true,
        });
      }
    }
  },

  logout: async () => {
    const { error } = await supabase.auth.signOut();
    useAuthStore.setState({
      isAuth: false,
      isError: false,
      isEmailSent: null,
      isLoading: false,
      session: null,
    });
  },

  loginEmail: async (email, redirectToPath) => {
    useAuthStore.setState({
      isLoading: true,
    });

    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: redirectTo(redirectToPath),
      },
    });

    if (error) {
      useAuthStore.setState({
        isLoading: false,
        isError: true,
      });

      reportError(error);
    } else {
      useAuthStore.setState({
        isLoading: false,
        isError: false,
        isEmailSent: true,
      });
    }
  },

  loginFacebook: async (redirectToPath) => {
    Auth._loginOAuth("facebook", redirectToPath);
  },

  loginGoogle: async (redirectToPath) => {
    Auth._loginOAuth("google", redirectToPath);
  },

  _loginOAuth: async (provider, redirectToPath) => {
    useAuthStore.setState({
      isLoading: true,
    });

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: redirectTo(redirectToPath),
      },
    });

    if (error) {
      reportError(error);
    }
  },
};

export default Auth;
