import React, { createRef } from "react";
import Trip from "../../../../stores/trip";
import MenuSection from "./section";

export default function URLSection(props) {
  const { day, doCloseParent } = props;
  const urlRef = createRef();

  const saveURL = (ev) => {
    ev.stopPropagation();
    const segment = {
      type: "URL",
      description: "Website",
      data: { url: urlRef.current.value },
    };

    Trip.addToDay(day, segment);
    doCloseParent();
  };

  return (
    <MenuSection
      icon="fa-link"
      text="Website URL"
      doCloseParent={doCloseParent}
    >
      <input
        type="text"
        ref={urlRef}
        className="w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      />
      <button
        className="float-right mt-1 rounded bg-slate-50 px-2 py-2 text-slate-900"
        onClick={saveURL}
      >
        Save
      </button>
    </MenuSection>
  );
}
