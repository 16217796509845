import React, { createRef } from "react";
import Recommender from "../../../../lib/recommender";
import Trip from "../../../../stores/trip";
import MenuSection from "./section";
import LocationButton from "./location";

export default function SearchSection(props) {
  const MAX_RESULTS = 15;
  const { day, doCloseParent } = props;
  const [results, setResults] = React.useState([]);
  const searchRef = createRef();

  const addLocationToDay = (id) => {
    Trip.addToDay(day, { type: "Location", locationId: id });
    doCloseParent ? doCloseParent() : null;
  };

  const renderResults = () =>
    results.map((location) => (
      <LocationButton location={location} onAdd={addLocationToDay} />
    ));

  return (
    <li className="cursor-pointer border-b border-slate-100">
      <div className="px-2 pb-2">
        <input
          ref={searchRef}
          className="w-full border border-slate-300 bg-white p-1 text-base text-slate-900"
          placeholder="Search..."
          onClick={(ev) => {
            ev.stopPropagation();
          }}
          onChange={(ev) => {
            if (searchRef.current.value.length >= 2) {
              const newResults = Recommender.search(
                searchRef.current.value,
                day,
              );
              setResults(newResults.slice(0, MAX_RESULTS));
            } else {
              setResults([]);
            }
          }}
        />
      </div>
      <div
        className={`mt-2 border-b-2 border-slate-600 ${
          results.length > 0 ? "" : "hidden"
        }`}
      >
        {renderResults()}
      </div>
    </li>
  );
}
