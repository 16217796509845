import React, { useEffect, useState, useRef } from "react";
import Auth from "../../stores/auth.js";

function Menu(props) {
  const { isAuth, session } = Auth.useStore();
  const [isOpen, setIsOpen] = useState(false);

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]); // Empty dependency array ensures it runs once on mount

  const dropdownRef = useRef(null);

  // Event listener to close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative z-40" ref={dropdownRef}>
      <button
        className="order-last inline-flex h-full grow-0 items-center px-4 align-middle hover:text-teal-600 focus:text-teal-600 active:text-teal-600 sm:px-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg">
          <i className="fas fa-bars" />
        </span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mr-2 w-48 origin-top-right divide-y divide-gray-100 border border-gray-200 bg-white text-sm shadow-lg">
          <ul>
            {isAuth && (
              <li>
                <button
                  className="block w-full cursor-pointer px-2 py-2 text-left text-slate-700 hover:bg-gray-50 hover:text-teal-600 focus:bg-gray-50 focus:text-teal-600"
                  onClick={() => {
                    Auth.logout();
                    setIsOpen(false);
                  }}
                >
                  Sign Out
                </button>
              </li>
            )}
            {!isAuth && (
              <li>
                <a
                  href="/my/trip/"
                  className="block w-full cursor-pointer px-2 py-2 text-left text-slate-700 hover:bg-gray-50"
                >
                  Sign In
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Menu;
