import { create } from "zustand";
import DB from "../lib/db";
import { reportEvent } from "../lib/helpers";
import Auth, { isAuth } from "./auth";

const LOCAL_STORAGE_KEY = "wishlist";

const useWishlistStore = create((set) => ({
  isLocalLoaded: false,
  isSyncing: false,
  localItems: null,
  initialIds: null,
}));

const getState = () => useWishlistStore.getState();
const setState = (state) => {
  useWishlistStore.setState(state);
};

const fetchLocalJSON = () => {
  const raw = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  let all;
  if (raw) {
    all = JSON.parse(raw);
  } else {
    all = {};
  }
  return all;
};

const unsubscribeAuth = Auth.useStore.subscribe((state, oldState) => {
  if (state.isAuth === true) {
    Wishlist._loadLocal();
    Wishlist.syncRemote();
  }
  if (state.isAuth === false && oldState.isAuth === true) {
    Wishlist._saveLocal({});
  }
});

const unixTime = () => Math.floor(new Date().getTime() / 1000);

const Wishlist = {
  useStore: useWishlistStore,

  init: () => {
    Wishlist._loadLocal();
  },

  count: () => {
    Wishlist._loadLocal();
    const state = getState();
    return Object.values(state.localItems).filter((item) => item.on).length;
  },

  hasItems: () => {
    Wishlist._loadLocal();
    const state = getState();
    return Object.values(state.localItems).some((item) => item.on);
  },

  isOnList: (id) => {
    Wishlist._loadLocal();
    const state = getState();
    if (typeof state.localItems[id] !== "undefined") {
      return state.localItems[id].on;
    }
    return false;
  },

  setOnList: (id, listed) => {
    const state = getState();
    Wishlist._setLocalList(id, listed);
    if (listed && !state.initialIds.includes(id)) {
      Wishlist.useStore.setState({ initialIds: state.initialIds.concat(id) });
    }
    reportEvent("wishlist", { id });
    Wishlist.syncRemote();
  },

  syncRemote: async () => {
    if (isAuth()) {
      const state = getState();
      if (!state.isSyncing) {
        setState({ isSyncing: true });
        Wishlist._ingestFromRemote();
        Wishlist._persistToRemote();
        setState({ isSyncing: false });
      }
    }
  },

  _loadLocal: () => {
    const state = getState();

    if (!state.isLocalLoaded) {
      const json = fetchLocalJSON();

      const newState = {
        localItems: json,
        isLocalLoaded: true,
      };

      if (state.initialIds === null) {
        const initialIds = Object.keys(json).filter((id) => json[id].on);
        newState.initialIds = initialIds;
      }

      setState(newState);
    }
  },

  _setLocalList: (id, listed) => {
    const state = getState();

    const all = state.localItems;
    all[id] = { on: listed, at: unixTime() };

    Wishlist._saveLocal(all);
  },

  _saveLocal: (all) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(all));

    setState({
      localItems: all,
    });
  },

  _persistToRemote: async () => {
    const state = getState();
    if (Object.keys(state.localItems).length > 0) {
      const records = Object.keys(state.localItems).map((id) => ({
        location_id: id,
        on: state.localItems[id].on,
        updated_at: new Date(state.localItems[id].at * 1000).toISOString(),
      }));

      await DB.upsert("wishlist", records);
    }
  },

  _ingestFromRemote: async () => {
    const state = getState();

    const data = await DB.select("wishlist", "location_id, on, updated_at");
    // TODO: Eventually scope this down to just this location to reduce bandwidth
    // .eq("location_id", this.props.locationId);

    if (data) {
      const newLocal = data.reduce((oldItems, item) => {
        if (
          oldItems[item.location_id] === undefined ||
          (item.at > oldItems[item.location_id].at &&
            item.on !== oldItems[item.location_id].on)
        ) {
          oldItems[item.location_id] = {
            on: item.on,
            at: new Date(item.updated_at).getTime() / 1000,
          };

          return oldItems;
        }
        return oldItems;
      }, state.localItems);
      Wishlist._saveLocal(newLocal);
    }
  },
};

export default Wishlist;
