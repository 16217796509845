import React, { useState } from "react";

function MenuSection(props) {
  const { children, icon, text, styles, doClick, doCloseParent } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <li
      className={`cursor-pointer border-b border-slate-100 px-2 py-3 sm:py-2 ${
        styles || ""
      } ${
        isMenuOpen
          ? "border-slate-700 bg-slate-600 text-white hover:bg-slate-600"
          : "border-slate-100 hover:bg-slate-50"
      }`}
      onClick={(ev) => {
        ev.stopPropagation();
        if (doClick) {
          doClick();
          doCloseParent();
          setMenuOpen(false);
        } else {
          setMenuOpen(!isMenuOpen);
        }
      }}
    >
      <div className="">
        <i className={`fas mr-1 ${icon}`} />
        {text}
      </div>
      <div className={`mt-2 ${isMenuOpen ? "" : "hidden"}`}>{children}</div>
    </li>
  );
}

export default MenuSection;
