import Atlas from "../stores/atlas";

export const blankPathSegment = {
  id: null,
  location: null,
  station: null,
  name: null,
  description: null,
  geo: null,
  mode: null,
  distance: null,
  walkDistance: null,
  duration: null,
  line: null,
  skip: false,
};

const PathFormat = {
  format: (path, nodes, lines) => {
    const reversed = path.reverse();
    const directed = reversed.map((node, idx) => {
      let nodeDef = null;
      let prevNode = null;
      const nextNode = reversed[idx + 1];

      let pathSegment = {
        ...blankPathSegment,
        id: node.id,
        geo: [node.data.lng, node.data.lat],
      };

      if (node.data.type === "location") {
        nodeDef = Atlas.find(node.id);
        pathSegment = {
          ...pathSegment,
          name: nodeDef.title,
          location: nodeDef,
        };
      } else {
        nodeDef = nodes[node.id];
        if (node.data.type === "station") {
          pathSegment = {
            ...pathSegment,
            name: nodeDef.name,
            station: nodeDef,
          };
        }
      }

      if (idx > 0) {
        prevNode = reversed[idx - 1];
        const link = [...node.links].find(
          (n) =>
            [prevNode.id, node.id].includes(n.fromId) &&
            [prevNode.id, node.id].includes(n.toId),
        );

        pathSegment = {
          ...pathSegment,
          distance: link.data.distance,
          walkDistance: link.data.walkingDistance,
          duration: link.data.duration,
          mode: link.data.mode,
        };
      } else {
        pathSegment = {
          ...pathSegment,
          description: `From ${pathSegment.name}`,
          skip: true,
        };
      }

      const line = lines[pathSegment.mode];
      if (line) {
        pathSegment.line = line;
      }

      if (pathSegment.mode === "walk") {
        if (pathSegment.station) {
          pathSegment.description = `Walk ${pathSegment.distance}m to ${pathSegment.name} Station`;
        } else {
          pathSegment.description = `Walk ${pathSegment.distance}m toward ${pathSegment.name}`;
        }
      }
      if (pathSegment.mode === "transfer") {
        const [stationId, lineId] = node.id.split("-");
        if (lineId) {
          const transferLine = lines[lineId];
          const nextId = nextNode.id.split("-")[0];
          const nextStop = nodes[nextId];
          pathSegment.description = `Board the ${transferLine.name} towards ${nextStop.name}`;
          pathSegment.line = transferLine;
          pathSegment.mode = "board";
        } else {
          pathSegment.description = `Disembark at ${pathSegment.name} Station`;
          pathSegment.mode = "disembark";
        }
      }
      if (pathSegment.mode && pathSegment.mode.startsWith("rec")) {
        // Travelling on the line
        const [stationId, lineId] = node.id.split("-");
        pathSegment.description = nodes[stationId].name;
        pathSegment.skip = true;
      }

      return pathSegment;
    });

    const summary = PathFormat._generateSummary(directed);

    return {
      summary,
      path: directed,
    };
  },

  _generateSummary: (directed) => {
    const summary = directed.reduce(
      (acc, node, idx) => {
        if (idx > 0) {
          acc.distance += node.distance;
          acc.duration += node.duration;
          acc.cost += 0;
          if (node.line && node.line.type) {
            acc.modes = [...new Set([...acc.modes, node.line.type])];
            acc.lines = [...new Set([...acc.lines, node.line.name])];
          }
          if (node.mode === "walk") {
            acc.walkDistance += node.distance;
          }
        }
        return acc;
      },
      {
        duration: 0,
        distance: 0,
        walkDistance: 0,
        cost: 0,
        modes: [],
        lines: [],
      },
    );

    return summary;
  },
};

export default PathFormat;
