import React, { useEffect } from "react";
import Auth from "../../stores/auth.js";
import IdeasNav from "./ideas_nav.jsx";
import TripNav from "./trip_nav.jsx";
import Menu from "./menu.jsx";

function AccountNav(props) {
  const { isAuth, isLoading, isInitialized, session } = Auth.useStore();

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]); // Empty dependency array ensures it runs once on mount

  const renderIdeas = () => <IdeasNav />;

  const renderTrip = () => <TripNav />;

  const renderMenu = () => <Menu />;

  const renderUnauthed = () => (
    <>
      {renderTrip()}
      {renderMenu()}
    </>
  );

  const renderAuthed = () => (
    <>
      {renderTrip()}
      {renderMenu()}
    </>
  );

  if (isLoading || !isInitialized) {
    return <></>;
  }
  return <>{!isAuth ? renderUnauthed() : renderAuthed()}</>;
}

export default AccountNav;
