import { create } from "zustand";
import createGraph from "ngraph.graph";
import pathFinder from "ngraph.path";
import { reportError } from "../lib/helpers";
import PathFormat from "../lib/path_format";
import Atlas from "./atlas";

export const NODES_URL = `${WEBSITE_ROOT}/geojson/transit.geojson`;
const EDGES_URL = `${WEBSITE_ROOT}/geojson/paths.json`;
const LINES_UL = `${WEBSITE_ROOT}/geojson/lines.json`;

const MAX_WALK_DISTANCE = 10000; // 10km

// We have a scale of walking weight based on the length. This means it will
// prefer to go for a short walk rather than take a train for a short distance.
const WEIGHT_WALKING = {
  500: 1.0, // 0-500m duration weight is 1x
  800: 1.5, // 500-800m duration weight is 1.5x.
  1000: 2.0,
  2000: 5.0,
};

const useDirectionsStore = create((set) => ({
  isLoaded: false,
  isLoading: false,
  graph: null,
  nodes: [],
}));

const unsubscribeAtlas = Atlas.useStore.subscribe((state, oldState) => {
  if (state.isLoaded === true) {
    Directions.init();
  }
});

const Directions = {
  useStore: useDirectionsStore,

  init: () => {
    const state = useDirectionsStore.getState();
    if (state.isLoaded || state.isLoading) {
      return;
    }

    useDirectionsStore.setState({ isLoading: true });

    const update = {
      isLoading: false,
    };

    if (state.graph === null || !state.isLoaded) {
      Directions._fetchRemoteJSON();
    }

    useDirectionsStore.setState(update);
  },

  pathfind: (originId, targetId) => {
    if (originId === null || targetId === null) {
      return null;
    }

    const state = useDirectionsStore.getState();
    if (!state.isLoaded) {
      return null;
    }

    const path = Directions._buildPathfinder(
      state.graph,
      originId,
      targetId,
    ).find(originId, targetId);

    if (path.length === 0) {
      reportError("No path found", { originId, targetId });
    }

    if (path) {
      return PathFormat.format(path, state.nodes, state.lines);
    }
    return path;
  },

  _getRemote: async (url) => {
    try {
      const response = await fetch(url, { cache: "default" });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      reportError(error);
      return null;
    }
  },

  _fetchRemoteJSON: async () => {
    const nodeJSON = await Directions._getRemote(NODES_URL);
    const edgeJSON = await Directions._getRemote(EDGES_URL);
    const linesJSON = await Directions._getRemote(LINES_UL);

    if (nodeJSON && edgeJSON) {
      const graph = createGraph();
      const nodes = {};

      nodeJSON.features.forEach((obj) => {
        const key = obj.properties.id;
        const value = {
          ...obj.properties,
          ...{ pos: obj.geometry.coordinates },
        };

        graph.addNode(key, {
          type: "station",
          name: TRUNDLE_PROFILE === "staging" ? value.name : null,
          lat: value.pos[1],
          lng: value.pos[0],
        });
        value.lines.forEach((lineId) => {
          const line = linesJSON[lineId];

          if (line) {
            graph.addNode(`${key}-${lineId}`, {
              name:
                TRUNDLE_PROFILE === "staging"
                  ? `${value.name}, ${line.name}`
                  : null,
              type: "platform",
            });

            graph.addLink(key, `${key}-${lineId}`, {
              mode: "transfer",
              line,
              walkingDistance: 25,
              distance: 25,
              duration: Math.ceil(0.5 + line.fr * 0.25), // Estimated wait time for a train based on the line frequency. Only count 25%, because average will be 50% of the wait time, and we count this on both boarding and alighting.
            });
          }
        });

        nodes[key] = value;
      });

      Object.entries(Atlas.useStore.getState().locations).forEach(
        ([id, location]) => {
          graph.addNode(id, {
            type: "location",
            name: TRUNDLE_PROFILE === "staging" ? location.name : null,
            lat: location.geo[1],
            lng: location.geo[0],
          });
          nodes[id] = location;
        },
      );

      edgeJSON.forEach((edge) => {
        if (edge[3] <= MAX_WALK_DISTANCE) {
          let weight = 1;
          let walkingDistance = edge[3];

          if (edge[2].startsWith("rec")) {
            // It's a segment of a line, so it has a weight, and no walking involved.
            weight = linesJSON[edge[2]].w;
            walkingDistance = 0;
          }

          graph.addLink(edge[0], edge[1], {
            mode: edge[2],
            walkingDistance,
            distance: edge[3],
            duration: edge[4],
            weight,
          });
        }
      });

      useDirectionsStore.setState({
        graph,
        nodes,
        lines: linesJSON,
        isLoaded: true,
      });
    }
  },

  _buildPathfinder: (graph, fromId, toId) => {
    const pathfinder = pathFinder.nba(graph, {
      distance(fromNode, toNode, link) {
        // TODO: This needs to weight cost too. That will help with
        // discouraging expensive routes like Shinkansen for short routes.

        let weight;
        if (link.data.mode.startsWith("rec")) {
          // Weight the travel time by the line's weight. This helps to
          // discourage Shinkansen and other fast trains for short journeys.
          weight = link.data.weight;
        } else {
          // It's walking or transiting. So, the weight is dependent on the
          // length.
          const walkKey = Object.keys(WEIGHT_WALKING).find(
            (k) => k >= link.data.walkingDistance,
          );
          const walkWeight = WEIGHT_WALKING[walkKey];

          weight = link.data.duration * walkWeight;

          // We need to discourage "Location" hopping by the router to break a
          // long journey (that we wouldn't select to walk) into smaller steps
          // by injecting pointless Location visits.
          if (
            fromNode.data &&
            toNode.data &&
            fromNode.data.type === "location" &&
            toNode.data.type === "location"
          ) {
            // It's a link between locations, so investigate further.
            if (
              !(
                [fromId, toId].includes(fromNode.id) &&
                [fromId, toId].includes(toNode.id)
              )
            ) {
              // It's not a direct link, so add an artificial delay to discourage this.
              weight += 20;
            }
          }
        }

        return link.data.duration * weight;
      },
      heuristic(fromNode, toNode) {
        if (fromNode.data && toNode.data) {
          if (
            fromNode.data.type === "platform" ||
            toNode.data.type === "platform"
          ) {
            // This must underestimate the station connection time, so make it 1 minute.
            return 1;
          }

          // Estimate the path at 3km/h walking speed.
          return (
            (Directions._distance(
              [fromNode.data.lng, fromNode.data.lat],
              [toNode.data.lng, toNode.data.lat],
            ) /
              3) *
            60.0
          );
        }
        // Weird link, make it really overestimate the cost because we don't know what it is.
        return 99999999;
      },
    });

    return pathfinder;
  },

  _distance(from, to) {
    const R = 6371; // Earth's radius in kilometers
    const lat1 = (from[1] * Math.PI) / 180; // Convert degrees to radians
    const lat2 = (to[1] * Math.PI) / 180;
    const deltaLat = ((to[1] - from[1]) * Math.PI) / 180;
    const deltaLng = ((to[0] - from[0]) * Math.PI) / 180;

    // Apply Haversine formula
    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) *
        Math.cos(lat2) *
        Math.sin(deltaLng / 2) *
        Math.sin(deltaLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  },
};

export default Directions;
