import { create } from "zustand";
import { reportError } from "../lib/helpers";

const ATLAS_URL = `${WEBSITE_ROOT}/geojson/japan.geojson`;

const useAtlasStore = create((set) => ({
  isLoaded: false,
  isLoading: false,
  isError: false,
  locations: {},
  _searchIndex: [],
}));

const Atlas = {
  useStore: useAtlasStore,

  blank: () => ({}),

  init: () => {
    const state = useAtlasStore.getState();
    if (!state.isLoaded && !state.isLoading && !state.isError) {
      Atlas._fetchGeoJSON();
    }
  },

  find: (id) => {
    Atlas.init();
    const state = useAtlasStore.getState();
    return state.locations[id];
  },

  locations: () => {
    Atlas.init();
    const state = useAtlasStore.getState();
    return Object.values(state.locations);
  },

  _fetchGeoJSON: async () => {
    useAtlasStore.setState({ isLoading: true });

    try {
      const response = await fetch(ATLAS_URL, { cache: "default" });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const json = await response.json();
      const locations = json.features.reduce((acc, feature) => {
        const combined = {
          ...feature.properties,
          ...{ geo: feature.geometry.coordinates },
        };
        acc[feature.properties.id] = combined;
        return acc;
      }, {});

      useAtlasStore.setState({
        isLoading: false,
        isLoaded: true,
        isError: false,
        locations,
        _searchIndex: Object.values(locations).map((item) => ({
          id: item.id,
          textContent: [item.title, item.region, item.neighbourhood].join(" "),
        })),
      });
    } catch (error) {
      reportError(error);
      useAtlasStore.setState({
        isLoading: false,
        isLoaded: false,
        isError: true,
      });
    }
  },
};

export default Atlas;
