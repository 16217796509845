import Atlas from "../stores/atlas.js";
import Trip from "../stores/trip.js";
import Wishlist from "../stores/wishlist.js";

const Recommender = {
  allPlannedLocations: () => {
    const state = Trip.useStore.getState();
    const locations = state.days
      .flatMap((day) => day.segments.map((seg) => seg.location))
      .filter((loc) => loc);
    const unique = [...new Set(locations)];
    return unique;
  },

  lastRegionForDay: (day) => day.endRegion,

  unusedWishlist: () => {
    const { initialIds } = Wishlist.useStore.getState();
    const usedIds = Recommender.allPlannedLocations().map((loc) => loc.id);

    return initialIds
      .filter(
        (id) =>
          // Remove all Wishlist items already on the Plan
          !usedIds.includes(id),
      )
      .map((id) => Atlas.find(id))
      .filter((loc) => loc);
  },

  wishlistForDay: (day) => {
    const lastRegion = Recommender.lastRegionForDay(day);

    const unusedWishlist = Recommender.unusedWishlist();
    const regionWishlist = lastRegion
      ? unusedWishlist.filter(
          (loc) =>
            // Remove all Wishlist items not in the last region
            loc && loc.region === lastRegion,
        )
      : null;

    if (regionWishlist === null || regionWishlist.length === 0) {
      return unusedWishlist;
    }
    return regionWishlist;
  },

  suggestions: () =>
    // TODO: Implement properly
    [Atlas.find("rec4TtQpqgXuFo6CK")],

  search: (term, dayContext) => {
    const state = Atlas.useStore.getState();
    const unusedWishlist = Recommender.unusedWishlist();

    return state._searchIndex
      .filter((item) =>
        item.textContent.toLowerCase().includes(term.toLowerCase()),
      )
      .map((item) => {
        const location = Atlas.find(item.id);
        const meta = {
          onTrip: false,
          wishlisted: false,
          suggested: false,
          relevance: 0,
        };
        if (
          dayContext &&
          (dayContext.startRegion === location.region ||
            dayContext.endRegion === location.region)
        ) {
          meta.relevance += 1;
        }
        if (unusedWishlist.find((l) => l.id === location.id)) {
          meta.relevance += 1;
        }

        return { ...location, ...meta };
      })
      .sort((a, b) => {
        // Sort the results first by relevance, and then alphabetically
        if (a.relevance > b.relevance) {
          return -1;
        }
        if (a.relevance < b.relevance) {
          return 1;
        }
        return a.title.localeCompare(b.title);
      });
  },
};

export default Recommender;
