import React, { useEffect, useState, useRef } from "react";
import Wishlist from "../../stores/wishlist.js";
import Login from "../login.jsx";

function SignupPitch(props) {
  const { title } = props;

  const header = () => {
    if (title && title !== "") {
      if (Wishlist.count() === 0) {
        return `${title} awaits for your journey!`;
      }
      return `${title} + ${Wishlist.count()} more await!`;
    }
  };

  const term = () => {
    if (title && title !== "") {
      return title;
    }
    return "your journey";
  };

  return (
    <>
      <h3 className="bg-teal-50 bg-teal-600 p-2 font-bold text-white">
        <i className="fas fa-check-square mr-1" />
        Lock In Your Discoveries!
      </h3>
      <div className="mt-2 text-center">
        <div className="p-1">
          <div className="font-bold">Secure Your Finds — It's Free! ✨</div>
          Create your account now. Don't miss the chance to make your trip
          unforgettable!
        </div>
        <div className="pt-1 text-sm font-bold">Why Join?</div>
      </div>
      <ul
        className="
    grid
    grid-cols-1
    gap-2
    p-2
    pb-0
    align-middle

                text-sm
                sm:grid-cols-2
                md:grid-cols-2
    md:gap-3
              "
      >
        <li>
          <div className="font-bold">
            <i className="fas fa-search" /> Tailored Discoveries
          </div>
          <div>Get spot-on recommendations and uncover hidden gems.</div>
        </li>
        <li>
          <div className="font-bold">
            <i className="fas fa-hourglass-half" /> Skip the Crowds
          </div>
          <div>
            Know the insider spots and maximize your joy, not your wait time.
          </div>
        </li>
        <li>
          <div className="font-bold">
            <i className="fas fa-map-marked-alt" /> Your Trip, Streamlined
          </div>
          <div>From itinerary to budget - plan effortlessly.</div>
        </li>
        <li>
          <div className="font-bold">
            <i className="fas fa-bell" /> Stay Updated
          </div>
          <div>Closures, changes, events? Be the first to know.</div>
        </li>
      </ul>
      <div className="p-2 text-center text-sm">
        <span className="font-bold">Dive into {term()} and beyond...</span>
      </div>
      <div className="mt-2 bg-teal-50 px-4 py-2">
        <Login
          hideHeader
          redirectToPath={window.location.pathname}
          ctaText="Sign Up"
        />
      </div>
    </>
  );
}

export default SignupPitch;
