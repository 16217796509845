import React, { useEffect } from "react";
import Auth from "../../stores/auth.js";
import Wishlist from "../../stores/wishlist.js";

function TripNav(props) {
  const { isAuth, isPending, session } = Auth.useStore();
  const { localItems } = Wishlist.useStore();

  const loadInitialAuthState = Auth.status;

  useEffect(() => {
    loadInitialAuthState();
  }, [loadInitialAuthState]);

  const renderWishlistBadge = () => {
    if (!Auth.isAuth() && Wishlist.hasItems()) {
      return (
        <span
          className="
        absolute
        right-0
        top-0
        translate-x-0
        translate-y-1
        transform
        text-xs
        text-amber-400
      "
        >
          <i className="fas fa-exclamation-triangle" />
        </span>
      );
    }
  };

  return (
    <>
      <a
        href="/my/trip/"
        className="relative inline-flex grow-0 items-center px-2 align-middle text-sm"
      >
        <span>Planner</span>
      </a>
      <a
        href="/my/wishlist/"
        className="relative inline-flex grow-0 items-center px-2 align-middle text-sm"
      >
        <span>Wishlist</span>
        {renderWishlistBadge()}
      </a>
    </>
  );
}

export default TripNav;
