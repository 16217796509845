import { create } from "zustand";
// import { persist, createJSONStorage } from "zustand/middleware";
import DB from "../lib/db";
import Auth, { isAuth } from "./auth";
// TODO: Either this needs to not be dependent on Atlas, or we need to wait for Atlas to load before we load the store...
import Atlas from "./atlas";
// import { getWalkingRoute } from "../lib/directions";
import Directions from "./directions";

const blankTransit = {
  id: null, // RemoteID for saving.
  description: "Walk", // Textual summary
  type: "Walk", // Walk|Car|Taxi|Train|Bus
  seq: 0, // Sequence
  // originEid: null, // EntranceId, assumed coords of previous segment exit if absent.
  // destinationEid: null, // EntranceId, assumed coords of next segment entry if absent.
  duration: null,
  distance: null,
  cost: null,
  originEid: null, // EntranceId, assumed coords of location if absent
  destinationEid: null, // EntranceId, assumed coords of location if absent
  polyline: null,
};

const blankSegment = {
  id: null, // RemoteID for saving.
  type: "Blank", // Blank|Flight|Hotel|Activity|Note|URL
  seq: 0, // Sequence
  description: "",
  locationId: null, // LocationId - eventually migrate under entry.
  geo: null, // The geo cords of this location
  // alerts: [],
  // schedule: {
  //   type: "Flex", // Flex|Strict
  //   startTime: null,
  //   endTime: null,
  // },
  // originEid: null, // EntranceId, assumed coords of previous segment exit if absent.
  transitStatus: null, // loading|loaded|error
  transitRoute: [],
  // entry: {
  //   lid: null, // LocationId
  //   // eid: null, // EntranceId, assumed coords of location if absent.
  //   duration: null,
  // },
  // exit: {
  //   // eid: null, // EntranceId, assumed same as entry.eid if absent.
  // },
  data: {},
};

const blankDay = {
  id: null, // RemoteID for saving.
  seq: 0, // Sequence
  description: "",
  startRegion: "",
  endRegion: "",
  segments: [],
};

// const changeset = {
//   at: null,
//   origin: "User", // User|System|Snapshot
//   action: {},
// };

const blankTrip = {
  id: null,
  days: [
    {
      ...blankDay,
      ...{
        description: "Start your plan",
        segments: [
          {
            ...blankSegment,
            ...{
              seq: 0,
              type: "Note",
              description: `Welcome to your new trip!\n\nTap the + Add Plans button to add items from your wishlist, new days, or anything you need to note for your trip. Items - including days - can be dragged to rearrange into a new order. We'll do our best to automatically work out the most convenient route between stops - just tap to expand the directions.\n\nOnce you're ready, you can tap this note to delete it. Of course, please reach out to us at contact@trundlejapan.com for anything you need!\n\nHave a great trip!`,
            },
          },
          {
            ...blankSegment,
            ...{
              seq: 1,
              type: "Flight",
              description: "Arrive in Japan",
              transitRoute: [],
            },
          },
        ],
      },
    },
  ],
};

const useTripStore = create((set) => ({
  isRequired: false,
  isLoaded: false,
  isSyncing: false,
  isUpdating: false,
  id: null,
  days: [],
}));

const unsubscribeAuth = Auth.useStore.subscribe((state, oldState) => {
  if (
    state.isAuth &&
    useTripStore.getState().isRequired &&
    !useTripStore.getState().isLoaded
  ) {
    Trip.init();
  }
});

// TODO: Honestly, probably should push all these back up into the store?
const Trip = {
  useStore: useTripStore,

  init: async () => {
    const state = useTripStore.getState();
    useTripStore.setState({ isRequired: true });

    if (!state.isLoaded && !state.isSyncing && isAuth()) {
      useTripStore.setState({ isSyncing: true });
      const trip = await Trip._tripFromRemote();

      if (trip === null || trip.id === null) {
        // No trip present, so init and save it
        useTripStore.setState({
          ...blankTrip,
        });
        await Trip._persistToRemote();
        useTripStore.setState({ isLoaded: true });
      } else {
        useTripStore.setState({
          id: trip.id,
          days: trip.days,
          isSyncing: false,
          isLoaded: true,
        });
      }
    }
  },

  day: (seq) => {
    const state = useTripStore.getState();
    return state.days.find((day) => day.seq === seq);
  },

  addDay: (afterSeq, day) => {
    const state = useTripStore.getState();
    const { days } = state;

    let newSeq;
    let newDay;

    if (typeof afterSeq === "undefined") {
      newSeq = days.length;
    } else {
      newSeq = afterSeq + 1;
    }

    const defaultDay = { seq: newSeq, segments: [] };
    if (day) {
      newDay = { ...defaultDay, ...day };
    } else {
      newDay = defaultDay;
    }
    const newDays = days.map((d) => {
      if (d.seq >= newSeq) {
        d.seq += 1;
      }
      return d;
    });
    newDays.splice(newSeq, 0, newDay);

    useTripStore.setState({ days: newDays });
    Trip._postprocess();
  },

  setDay: (seq, day) => {
    const state = useTripStore.getState();
    const days = state.days.map((d) => {
      if (d.seq === seq) {
        return day;
      }
      return d;
    });
    useTripStore.setState({ days });
    Trip._postprocess();
  },

  deleteDay: (day) => {
    const state = useTripStore.getState();
    let days;
    if (state.days.length > 1) {
      days = state.days.filter((d) => d.seq !== day.seq);
    } else {
      days = [{ ...blankDay }];
    }
    useTripStore.setState({ days });
    Trip._postprocess();
  },

  addToDay: (day, segment) => {
    const state = useTripStore.getState();

    const { segments } = day;

    const storedSegment = {
      ...blankSegment,
      ...segment,
      seq: day.segments.length + 1,
    };
    segments.push(storedSegment);

    const newDay = { ...day, ...{ segments } };
    const newDays = state.days.map((d) => {
      if (d.seq === day.seq) {
        return newDay;
      }
      return d;
    });
    useTripStore.setState({ days: newDays });
    Trip._postprocess();
  },

  removeFromDay: (seq, segment) => {
    const state = useTripStore.getState();
    const day = Trip.day(seq);
    const segments = day.segments.filter((s) => s.seq !== segment.seq);
    const newDay = { ...day, ...{ segments } };
    const newDays = state.days.map((d) => {
      if (d.seq === seq) {
        return newDay;
      }
      return d;
    });
    useTripStore.setState({ days: newDays });
    Trip._postprocess();
  },

  addLocationToEnd: (location) => {
    const state = useTripStore.getState();
    const lastDay = state.days.slice(-1)[0];
    Trip.addToDay(lastDay, {
      ...blankSegment,
      ...{
        type: "Location",
        locationId: location.id,
      },
    });
  },

  moveSegmentByIds: (segmentId, toDayId, toSeq) => {
    const state = useTripStore.getState();
    // Find the segment by id from all the days
    const segment = state.days
      .flatMap((day) => day.segments)
      .find((seg) => seg.id === segmentId);

    // Remove the segment from the original day
    const originalDay = state.days.find((day) =>
      day.segments.includes(segment),
    );
    originalDay.segments = originalDay.segments.filter(
      (seg) => seg.id !== segmentId,
    );

    // Add the segment to the new day, in the correct toDaySeq position
    const newDay = state.days.find((day) => day.id === toDayId);
    newDay.segments.splice(toSeq, 0, segment);

    const newDays = state.days.map((day) => {
      if (day.id === originalDay.id) {
        return originalDay;
      }
      if (day.id === newDay.id) {
        return newDay;
      }
      return day;
    });

    useTripStore.setState({ days: newDays });
    Trip._postprocess();
  },

  moveDayByIds: (dayId, toSeq) => {
    const state = useTripStore.getState();
    // Find the day by id
    const day = state.days.find((d) => d.id === dayId);
    // Remove the day from the original position
    const days = state.days.filter((d) => d.id !== dayId);
    // Add the day to the new position
    days.splice(toSeq, 0, day);

    // Recalculate the seq on all the days
    useTripStore.setState({ days });
    Trip._postprocess();
  },

  _isAwaitingRequests: () => {
    const state = useTripStore.getState();
    const result =
      state.days
        .flatMap((day) => day.segments)
        .filter((segment) => segment.transitStatus === "loading").length > 0;
    return result;
  },

  findLocationSegment: (location) => {
    const state = useTripStore.getState();
    const segment = state.days
      .flatMap((day) => day.segments)
      .find((segment) => segment.locationId === location.id);

    if (segment) {
      return {
        day: state.days.find((day) => day.segments.includes(segment)),
        segment,
      };
    }
    return null;
  },

  _reseqMap(array, fn) {
    const newArr = array.reduce((acc, x) => {
      const newX = fn ? fn(x) : x;

      acc.push({ ...newX, seq: acc.length });
      return acc;
    }, []);

    return newArr;
  },

  _getRegions: (segments) => {
    // TODO: This should be more aware of previous and next days
    const regions = segments
      .map((segment) => {
        if (segment.locationId) {
          const location = Atlas.find(segment.locationId);
          return location.region;
        }
        if (segment.type === "Relocate") {
          return segment.data.destinationRegion;
        }
        return null;
      })
      .filter((region) => region !== null);

    return {
      startRegion: regions[0],
      endRegion: regions[regions.length - 1],
    };
  },

  _postprocess: () => {
    const state = useTripStore.getState();
    useTripStore.setState({ isUpdating: true });

    // Recalculate sequence on all the days and segments based on order
    const newDays = Trip._reseqMap(state.days, (day) => {
      const newSegments = Trip._reseqMap(day.segments);
      return { ...day, ...{ segments: newSegments } };
    })
      .map((day) => {
        // Remove any segments that are a Location but have a null locationId
        const newSegments = day.segments.filter(
          (segment) =>
            !(segment.type === "Location" && segment.locationId === null),
        );
        return { ...day, ...{ segments: newSegments } };
      })
      .map((day) => Trip._postprocessDay(day));

    useTripStore.setState({ days: newDays, isUpdating: false });
    Trip._persistToRemote();
  },

  _postprocessDay: (originalDay) => {
    const day = originalDay;

    // TODO: Set the startRegion and endRegion based on previous day and last segment of the day
    // Set the day title/region based on the first segment in the day
    // TODO: or the region on the previous day
    if (day.segments.length > 0) {
      const calculatedRegions = Trip._getRegions(day.segments);
      day.startRegion = calculatedRegions.startRegion;
      day.endRegion = calculatedRegions.endRegion;

      if (day.startRegion === null) {
        day.description = `Arriving in Japan`;
      } else if (day.startRegion === day.endRegion) {
        day.description = day.startRegion;
      } else {
        day.description = `${day.startRegion} to ${day.endRegion}`;
      }

      // TODO: Ideally this should be reduce() so that _postprocessSegment can
      // be pure. Currently it relies on being able to access the mutated
      // previous segment.
      const newSegments = day.segments.map((segment, idx) =>
        Trip._postprocessSegment(segment, idx, day),
      );

      day.segments = newSegments;
    }

    return day;
  },

  _postprocessSegment: (segment, idx, day) => {
    // WARNING! This function is not pure, it mutates the segment object. This
    // sucks, but (given it's called in map()) it means we don't have to pass
    // in the calculated previous Segment (see the related TODO). In fact, the
    // previous day logic below (see TODO) is already broken due to this issue.
    let prevSegment;

    if (idx === 0 && day.seq > 0) {
      // BUG! TODO: This is broken, because geo is only allocated in the
      // process, and so requesting the previous day from the store won't have
      // geo set. The solution here is probably to refactor all the
      // _postprocess* fns to use reduce() instead of map(), and pass the
      // latest state of the trip into each call.
      const prevDay = Trip.day(day.seq - 1);
      if (prevDay) {
        prevSegment = prevDay.segments.slice(-1)[0];
      }
    } else if (idx >= 1) {
      prevSegment = day.segments[idx - 1];
    }

    if (segment.geo === null && segment.locationId) {
      if (segment.location) {
        segment.geo = segment.location.geo;
      } else {
        segment.geo = Atlas.find(segment.locationId).geo;
      }
    } else if (segment.geo === null && idx >= 1) {
      segment.geo = prevSegment.geo;
    }

    if (segment.type === "Location") {
      if (prevSegment && segment.geo && prevSegment.geo) {
        // This segment is eligible to have a transit, as we have the required data.
        if (
          segment.transitRoute === null ||
          segment.transitRoute.length === 0 ||
          segment.transitRoute[0].originLocationId !== prevSegment.locationId
        ) {
          // The segment needs to be created
          segment.transitStatus = "loading";

          const route = Directions.pathfind(
            prevSegment.locationId,
            segment.locationId,
          );

          return Trip._applyTransitToSegment(segment, prevSegment, route);
        } // otherwise, the existing segment looks fine so NO-OP
      } else if (
        segment.transitRoute !== null &&
        segment.transitRoute.length > 0
      ) {
        // This segment has transit but isn't eligible to have any. Delete them.
        segment.transitRoute = [];
        segment.transitStatus = null;
      }
      // TODO: else: What is this state?
    } // else, other segment types aren't eligible to have a transit yet, so NO-OP.

    return segment;
  },

  _applyTransitToSegment: (segment, prevSegment, directions) => {
    const newTransit = {
      ...blankTransit,
      ...{
        type: "Walk",
        seq: 0,
        originLocationId: prevSegment.locationId,
        duration: Math.ceil(directions.summary.duration),
        distance: Math.ceil(directions.summary.walkDistance / 100) * 100,
        data: directions,
        // polyline: directions.routes[0].geometry,
      },
    };

    if (directions.summary.modes.length > 0) {
      newTransit.type = directions.summary.modes[0];
      if (directions.summary.lines.length > 1) {
        newTransit.description = `${directions.summary.modes[0]} (${directions.summary.lines.length} lines)`;
        newTransit.type = directions.summary.modes[0];
      } else {
        newTransit.description = `${directions.summary.modes[0]} (${directions.summary.lines[0]})`;
        newTransit.type = directions.summary.modes[0];
      }
    }

    const newSegment = {
      ...segment,
      transitRoute: [newTransit],
      transitStatus: "loaded",
    };

    return newSegment;
  },

  _persistToRemote: async () => {
    // Do not allow to persist if there are async requests in progress.
    if (Trip._isAwaitingRequests()) {
      return;
    }

    const state = useTripStore.getState();
    useTripStore.setState({ isSyncing: true });

    const tripPayload = {
      id: state.id, // Will be removed if null
      updated_at: new Date(),
    };

    const tripResult = await DB.upsert("trips", [tripPayload]);
    const tripId = tripResult[0].id;

    const dayData = state.days.map((day) => ({
      id: day.id,
      seq: day.seq,
      description: day.description,
      trip_id: tripId,
    }));

    const dayResult = await DB.upsert("days", dayData);

    const segmentData = state.days.flatMap((day) =>
      day.segments.map((segment) => ({
        id: segment.id,
        seq: segment.seq,
        type: segment.type,
        description: segment.description,
        data: segment.data,
        location_id: segment.locationId,
        // TODO: Why can't I use day_id here?
        day_id: dayResult.find((uday) => uday.seq === day.seq).id,
      })),
    );

    const segmentResult = await DB.upsert("segments", segmentData);

    const transitData = state.days.flatMap((day) =>
      day.segments.flatMap((segment) => {
        const segResult = segmentResult.find(
          (useg) => useg.day_id === day.id && useg.seq === segment.seq,
        );

        return segment.transitRoute.map((transit) => ({
          id: transit.id,
          segment_id: segment.id || segResult.id,
          origin_location_id: transit.originLocationId,
          seq: transit.seq,
          type: transit.type,
          duration: transit.duration,
          distance: transit.distance,
          polyline: transit.polyline,
          data: JSON.stringify(transit.data),
          description: transit.description,
        }));
      }),
    );

    const transitResult = await DB.upsert("transits", transitData);

    // Delete any Days and Segments not in our dayResult and segmentResult
    const dayIds = dayResult.map((day) => day.id);
    const segmentIds = segmentResult.map((segment) => segment.id);
    const transitIds = transitResult.map((transit) => transit.id);
    DB.deleteExceptIds("days", dayIds);
    DB.deleteExceptIds("segments", segmentIds);
    DB.deleteExceptIds("transits", transitIds);

    // Update all the local IDs from the DB.
    const newTrip = await Trip._tripFromRemote(
      tripResult,
      dayResult,
      segmentResult,
      transitResult,
    );

    useTripStore.setState({
      id: newTrip.id,
      days: newTrip.days,
      isSyncing: false,
    });
  },

  _tripFromRemote: async (
    remoteTrip,
    remoteDays,
    remoteSegments,
    remoteTransits,
  ) => {
    const tripData = remoteTrip || (await DB.select("trips", "id"));

    if (tripData.length > 0) {
      const dayData = remoteDays || (await DB.select("days", "*"));
      const segmentData = remoteSegments || (await DB.select("segments", "*"));
      const transitData = remoteTransits || (await DB.select("transits", "*"));

      const days = dayData.map((day) => {
        const segments = segmentData
          .filter((segment) => segment.day_id === day.id)
          .sort((a, b) => a.seq - b.seq)
          .map((segment) => {
            const transits = transitData
              .filter((transit) => transit.segment_id === segment.id)
              .sort((a, b) => a.seq - b.seq)
              .map((transit) => ({
                ...blankTransit,
                ...{
                  id: transit.id,
                  seq: transit.seq,
                  type: transit.type,
                  duration: transit.duration,
                  distance: transit.distance,
                  polyline: transit.polyline,
                  description: transit.description,
                  originLocationId: transit.origin_location_id,
                  data:
                    typeof transit.data === "string"
                      ? JSON.parse(transit.data)
                      : transit.data,
                },
              }));

            return {
              ...blankSegment,
              ...{
                id: segment.id,
                seq: segment.seq,
                type: segment.type,
                description: segment.description,
                locationId: segment.location_id,
                data: segment.data,
                location: Atlas.find(segment.location_id),
                transitStatus: transits.length > 0 ? "loaded" : null,
                transitRoute: transits,
              },
            };
          });

        if (segments.length > 0) {
          const calculatedRegions = Trip._getRegions(segments);
          day.startRegion = calculatedRegions.startRegion;
          day.endRegion = calculatedRegions.endRegion;
        }

        return { ...day, ...{ segments } };
      });

      return { id: tripData[0].id, days };
    }
    return { ...blankTrip };
  },
};

export default Trip;
