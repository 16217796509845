import React, { useEffect } from "react";
import Atlas from "../../stores/atlas.js";
import Wishlist from "../../stores/wishlist.js";
import MapStore from "../../stores/map";

function WishlistItem(props) {
  const { id, isOnList } = props;
  const { isLoaded } = Atlas.useStore();

  const data = (isLoaded ? Atlas.find(id) : null) || Atlas.blank();
  const opacity = isOnList ? "" : " opacity-50";

  const onLocation = (ev) => {
    ev.stopPropagation();
    if (data) {
      MapStore.setFocusLocation(data);
    }
  };

  const renderButton = (text, doOnClick, icon, classes) => (
    <button
      className={`block flex-1 p-1 text-center align-middle ${classes}`}
      onClick={doOnClick}
      title={text}
    >
      <i className={`fas mr-1 ${icon}`} />
    </button>
  );

  const chooseButton = () => {
    if (isOnList) {
      return renderButton(
        "Delete",
        () => {
          Wishlist.setOnList(id, false);
          MapStore.refresh();
        },
        "fa-times",
        "bg-red-50 text-red-800 hover:bg-red-100 hover:text-red-900 focus:bg-red-100 focus:text-red-900 active:bg-red-800 active:text-white",
      );
    }
    return renderButton(
      "Add",
      () => {
        Wishlist.setOnList(id, true);
      },
      "fa-plus",
      "bg-slate-100 hover:bg-slate-300 hover:text-slate-900 focus:bg-slate-300 hover:text-slate-900",
    );
  };

  return (
    <div className="flex h-20 bg-white shadow-sm">
      <a href={data.permalink} className="block h-full flex-none">
        <img
          className={`aspect-video h-full object-cover ${opacity}`}
          loading="lazy"
          alt={`${data.title}, ${data.region}, Japan`}
          src={data.thumbnail_url}
        />
      </a>
      <h4
        className={`flex h-full flex-1 flex-col justify-center pl-2 text-sm font-bold pt-1${opacity}`}
      >
        {data.title}
      </h4>
      <div className="ml-2 flex h-full w-10 flex-none flex-col justify-center">
        {chooseButton()}
        {renderButton(
          "Show Location",
          onLocation,
          "fa-map-marked-alt",
          "bg-teal-50 text-teal-800 hover:bg-teal-100 hover:text-teal-900 focus:bg-teal-100 hover:text-teal-900",
        )}
      </div>
    </div>
  );
}

export default WishlistItem;
