import MapboxConfig from "../config/mapbox.js";

const { styles } = MapboxConfig;

// TODO: Rename this to Mapbox, as it's used for more than just the main maps
const MainMap = {
  init: (el, fn) => {
    // <script src='https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.js' crossorigin="anonymous"></script>
    // <link href='https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css' rel='stylesheet' crossorigin="anonymous" />
    const link = document.createElement("link");
    link.href = "https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css";
    link.rel = "stylesheet";
    link.crossorigin = "anonymous";

    const script = document.createElement("script");
    script.src = "https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.js";
    script.crossorigin = "anonymous";

    if (fn) {
      script.onload = fn;
    }
    if (el) {
      script.onload = () => {
        MainMap.afterInit(el);
      };
    }

    document.head.appendChild(link);
    document.head.appendChild(script);
  },

  afterInit: (el) => {
    const { mapboxgl } = window;
    mapboxgl.accessToken = MapboxConfig.mapboxToken;
    const config = {
      container: el,
      style: MapboxConfig.style,
    };
    if (
      el.attributes["data-zoom"] &&
      el.attributes["data-center-long"] &&
      el.attributes["data-center-lat"]
    ) {
      config.center = [
        el.attributes["data-center-long"].value,
        el.attributes["data-center-lat"].value,
      ];
      config.zoom = el.attributes["data-zoom"].value;
    }

    const map = new mapboxgl.Map(config);

    if (el.attributes["data-fit"] && el.attributes["data-fit"].value !== "") {
      const fit = JSON.parse(el.attributes["data-fit"].value);
      map.fitBounds(fit, { padding: 50 });
    }

    // Disable scroll zoom, because it makes page scrolling difficult
    map.scrollZoom.disable();
    // disable map rotation using right click + drag
    map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    const source = el.attributes["data-geojson"]
      ? el.attributes["data-geojson"].value
      : null;

    map.on("load", () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.addSource("locations", {
        type: "geojson",
        data: source,
        cluster: true,
        clusterMaxZoom: 13, // Max zoom to cluster points on
        clusterRadius: 25, // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "locations",
        filter: ["has", "point_count"],
        layout: {},
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          "circle-color": styles.legacy.clusterBackground,
          "circle-radius": ["step", ["get", "point_count"], 20, 10, 30, 25, 40],
          "circle-stroke-width": 1,
          "circle-stroke-color": styles.legacy.markerBorder,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "locations",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 14,
        },
        paint: {
          "text-color": styles.legacy.clusterText,
          "text-halo-color": styles.legacy.clusterBorder,
          "text-halo-width": 1.5,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "locations",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": styles.legacy.markerBackground,
          "circle-radius": 15,
          "circle-stroke-width": 1,
          "circle-stroke-color": styles.legacy.markerBorder,
        },
      });

      map.addLayer({
        id: "unclustered-title",
        type: "symbol",
        source: "locations",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "text-field": "{title}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 16,
          "text-offset": [0, 0],
        },
        paint: {
          "text-color": styles.legacy.markerText,
          "text-halo-color": styles.legacy.markerHaloColor,
          "text-halo-width": 1.5,
        },
      });

      // inspect a cluster on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("locations")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      map.on("click", "unclustered-point", (e) => {
        window.location = e.features[0].properties.permalink;
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });

      map.on("mouseenter", "unclustered-point", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "unclustered-point", () => {
        map.getCanvas().style.cursor = "";
      });
    });
  },
};

export default MainMap;
