import React from "react";

export default LocationButton = function (props) {
  const { location, onAdd } = props;

  return (
    <li
      className="justify-middle col-span-1 flex h-16 hover:bg-teal-50"
      onClick={() => {
        onAdd(location.id);
      }}
    >
      <img
        src={location.thumbnail_url}
        className="flex-0 aspect-video h-full"
      />
      <span className="ml-2 flex-1 text-sm font-bold">{location.title}</span>
      {true && <span className="text-xs text-teal-600">{location.region}</span>}
    </li>
  );
};
