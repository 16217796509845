import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import DayPlan from "./day_plan";
import Atlas from "../../stores/atlas";
import Trip from "../../stores/trip";
import Directions from "../../stores/directions";
import Loading from "./loading";

function TripPlan(props) {
  const {} = props;
  const { isLoaded: isTripLoaded, days } = Trip.useStore();
  const { isLoaded: isAtlasLoaded } = Atlas.useStore();

  const loadInitialTripState = Trip.init;
  useEffect(() => {
    loadInitialTripState();
  }, [loadInitialTripState]);
  const loadInitialAtlasState = Atlas.init;
  useEffect(() => {
    loadInitialAtlasState();
  }, [loadInitialAtlasState]);

  const renderDays = () =>
    days.map((day) => <DayPlan day={day} key={`day-${day.id || day.seq}`} />);

  const onDragEnd = (result) => {
    if (result.type === "segment") {
      Trip.moveSegmentByIds(
        result.draggableId,
        result.destination.droppableId,
        result.destination.index,
      );
    } else if (result.type === "day") {
      Trip.moveDayByIds(result.draggableId, result.destination.index);
    }
  };

  const onDragStart = (start) => {};

  return isTripLoaded ? (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="days" type="day">
        {(provided) => (
          <div
            className="flex w-full flex-col"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {renderDays()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <Loading />
  );
}

export default TripPlan;
